import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CoreState } from './store';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { environment } from 'src/environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NgxsActionsExecutingModule } from '../utils/actions-executing/actions-executing.module';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([CoreState]),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: CoreState,
    }),
    NgxsActionsExecutingModule.forRoot(),
    MatIconModule,
    MatSnackBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule {}
