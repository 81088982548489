import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, map, mergeMap, Observable, toArray } from 'rxjs';
import { UploadImageResponse } from '../store';

@Injectable({
  providedIn: 'root',
})
export class MvpService {
  private testToken = JSON.parse(localStorage.getItem('core') as string)
    .userToken;

  constructor(private http: HttpClient) {}

  public uploadImage(files: any): Observable<UploadImageResponse[]> {
    return from(files)
      .pipe(
        mergeMap((file) => {
          return this.http.post<UploadImageResponse>(
            `${environment.apiUrl}/attachment/image/${environment.botId}`,
            file,
          );
        }),
      )
      .pipe(toArray());
  }

  public getLocation(lat: number, lon: number): Observable<any> {
    return this.http.get(
      `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${lat}&lon=${lon}`,
      // `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=44.50155&lon=11.33989`,
      {
        headers: new HttpHeaders({
          'accept-language': 'en',
        }),
      },
    );
  }

  public addProduct(product: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/product`, product, {
      headers: {
        'X-Access-Token': this.testToken,
      },
    });
  }

  public createDataForAdvertisement(params: any): Observable<any> {
    return this.http.post(`${environment.techfunderUrl}/data`, params);
  }

  public createAdvertisement(
    userId: string,
    objectId: string,
  ): Observable<any> {
    return this.http.get(
      `${environment.techfunderUrl}/advertising?user_id=${userId}&object_id=${objectId}&project=realty_ai`,
    );
  }

  public editProduct(product: any, id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/product/${id}`, product, {
      headers: {
        'X-Access-Token': this.testToken,
      },
    });
  }

  getProduct(id: string, botId?: string): Observable<any> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/product/${id}`, //dev bot
    );
  }
}
