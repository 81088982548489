import {
  UserLoginData,
  UserProfile,
  UserRegistrationData,
} from './core-state.model';

/* ---------------------------------- Auth ---------------------------------- */
export class CreateUser {
  static readonly type = '[Core] Create user';
  constructor(public data: UserRegistrationData) {}
}

export class LoginUser {
  static readonly type = '[Core] Login user';
  constructor(public data: UserLoginData) {}
}

export class LogOut {
  static readonly type = '[Core] Logout';
}

export class ResendEmail {
  static readonly type = '[Core] Resend email';
  constructor(
    public data: UserLoginData,
    public token: string,
  ) {}
}

export class VerifyEmail {
  static readonly type = '[Core] Verify email';
  constructor(public hash: string) {}
}

export class LoginUserAfterEmailVerified {
  static readonly type = '[Core] Login user after email verified';
}

/* ---------------------------------- User ---------------------------------- */

export class GetUserProfile {
  static readonly type = '[Core] Get user profile';
}

export class UpdateUserProfile {
  static readonly type = '[Core] Update user profile';
  constructor(public profile: UserProfile) {}
}

/* --------------------------------- Layout --------------------------------- */

export class ToggleSidebarSize {
  static readonly type = '[Core] Toggle sidebar size';
}

/* ------------------------------ Dashboard TMP ----------------------------- */

export class GetUserAds {
  static readonly type = '[Core] Get user ads';
}

export class DeleteExposeProduct {
  static readonly type = '[Core] Delete Expose Product';
  constructor(public id: string) {}
}

/* ----------------------------------- ++ ----------------------------------- */

export class GetBotCategories {
  static readonly type = '[Core] Get Bot Categories';
  constructor() {}
}

export class CreateCustomer {
  static readonly type = '[Mvp] Create Customer';
  constructor(
    public login: string,
    public password: string,
    public name: string,
  ) {}
}

export class GetPersonalData {
  static readonly type = '[Core] Get personal data';
}

export class UpdatePersonalData {
  static readonly type = '[Core] Update personal data';
  constructor(
    public company: string,
    public address: string,
    public telephone: string,
    public mobile: string,
    public vs_retries?: string,
  ) {}
}

export class UpdateVSRetries {
  static readonly type = '[Core] Update VS Retries';
  constructor(public profile: UserProfile) {}
}

export class GenerateAIForProduct {
  static readonly type = '[Core] Generate AI For Product';
  constructor(
    public productId: string,
    public language: string,
  ) {}
}

export class LoginCustomer {
  static readonly type = '[Core] Login Customer';
  constructor(
    public login: string,
    public password: string,
  ) {}
}

export class SetCustomer {
  static readonly type = '[Core] SetCustomer';
  constructor(
    public customerId: string,
    public customerToken: string,
  ) {}
}

export class GetProducts {
  static readonly type = '[Core] Get products';
  constructor(public customerId: string) {}
}

export class GetStagingProduct {
  static readonly type = '[Core] Get Staging Product';
  constructor(public merchantId: string) {}
}
export class GetTrashbinImages {
  static readonly type = '[Core] Get Trashbin Images';
  constructor(public merchantId: string) {}
}
export class EditStagingProduct {
  static readonly type = '[Core] Edit Staging Product';
  constructor(
    public params: any,
    public id: string,
    public notSaveInVariable?: boolean,
  ) {}
}
export class EditFolder {
  static readonly type = '[Core] Edit Folder';
  constructor(
    public params: any,
    public id: string,
  ) {}
}
export class MoveImgsToFolder {
  static readonly type = '[Core] Move Imgs To Folder';
  constructor(
    public productFrom: any,
    public productTo: any,
  ) {}
}

export class CreateStagingProduct {
  static readonly type = '[Core] Create Staging Product';
  constructor(public product: any) {}
}

export class CreateFolderProduct {
  static readonly type = '[Core] CreateFolderProduct';
  constructor(public product: any) {}
}

export class GetFolderProducts {
  static readonly type = '[Core] GetFolderProducts';
  constructor(public merchantId: string) {}
}

export class SelectFolder {
  static readonly type = '[Core] SelectFolder';
  constructor(public productId: string) {}
}

export class ConfirmEmail {
  static readonly type = '[Core] Get email';
  constructor(public email: string) {}
}

export class ResetPassword {
  static readonly type = '[Core] Reset password';
  constructor(
    public token: string,
    public newPassword: string,
    public confirmPassword: string,
  ) {}
}

export class ClearErrorEmail {
  static readonly type = '[Core] Clear error email';
}
export class ClearCoreState {
  static readonly type = '[Core] ClearCoreState';
}

export class CreatePaymentIntent {
  static readonly type = '[Core] Create Payment Intent';
  constructor(public amount: number) {}
}

export class GetTransactionList {
  static readonly type = '[Core] Get Transaction List';
  constructor() {}
}

export class DeleteFolder {
  static readonly type = '[Core] Delete Folder';
  constructor(public id: string) {}
}
export class PaymentModalStatus {
  static readonly type = '[Core] Payment Modal Status';
  constructor(public status: boolean) {}
}

export class UploadImage {
  static readonly type = '[Core] Upload image';
  constructor(public file: FormData) {}
}

export class RegisterEmail {
  static readonly type = '[Core] Register email';
  constructor(public email: string) {}
}
