import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, onboardingGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./features/contacts/contacts.module').then(
        (m) => m.ContactsModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./features/calendar/calendar.module').then(
        (m) => m.CalendarModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'mail',
    loadChildren: () =>
      import('./features/mail/mail.module').then((m) => m.MailModule),
    canActivate: [authGuard],
  },

  {
    path: 'mvp',
    loadChildren: () =>
      import('./features/mvp/mvp.module').then((m) => m.MvpModule),
    canActivate: [authGuard],
  },
  {
    path: 'expose',
    loadChildren: () =>
      import('./features/expose/expose.module').then((m) => m.ExposeModule),
    canActivate: [authGuard],
  },
  {
    path: 'staging',
    loadChildren: () =>
      import('./features/staging/staging.module').then((m) => m.StagingModule),
    canActivate: [authGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./features/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule,
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./features/payments/payments.module').then(
        (m) => m.PaymentsModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'onboarding',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
