import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import {
  CoreSelectors,
  LogOut,
  UpdateUserProfile,
  UserPlan,
  UserProfile,
} from 'src/app/core/store';

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.scss'],
})
export class NavigationMobileComponent {
  @Input() title!: string;
  @ViewSelectSnapshot(CoreSelectors.userProfile)
  userProfile!: UserProfile;
  @ViewSelectSnapshot(CoreSelectors.userPlan)
  userPlan!: UserPlan;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.closeMenu();
  }

  @Input('type')
  type: 'main' | 'alt' = 'main';

  @Input('returnLink')
  returnLink?: string;

  @Input('returnTitle')
  returnTitle?: string;

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  constructor(
    private store: Store,
    private translate: TranslateService,
  ) {}
  // tmp
  logOut(): void {
    if (confirm('LogOut?')) {
      this.store.dispatch([new LogOut()]);
    }
  }

  closeMenu(): void {
    this.trigger?.closeMenu();
  }

  get userNameShort(): string {
    const userNameArr = this.userProfile?.name.split(' ');
    return (
      userNameArr[0].substring(0, 1) +
      (userNameArr[1] ? userNameArr[1].substring(0, 1) : '')
    ).toUpperCase();
  }

  get currentLanguage(): string {
    return this.translate.currentLang;
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
    const profile = this.userProfile;
    profile.language = language;
    this.store.dispatch(new UpdateUserProfile(profile));
  }
}
