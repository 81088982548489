<div class="profile-menu__container">
  <button
    mat-icon-button
    (click)="$event.stopPropagation()"
    class="mobile-sidebar__menu"
    [matMenuTriggerFor]="menu"
    aria-label="Page menu"
  >
    <p class="profile-menu__language title-expose title-expose--size-14">
      {{ getLanguage | uppercase }}
    </p>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let menuItem of menuItems; let i = index"
      (click)="onSelectTab(menuItem.language)"
      [disableRipple]="true"
    >
      <span class="title-expose title-expose--size-14">
        {{ menuItem.title }}
      </span>
    </button>
  </mat-menu>
</div>
