import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  AddProduct,
  CreateAdvertisement,
  EditNftProduct,
  UploadImage,
  SaveProductTemporaryInfo,
  GetLocation,
  GetProduct,
  AddDataForAdvertisement,
  GetPathname,
  ClearState,
  SetProductId,
} from './mvp.actions';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { MvpStateModel, UploadImageResponse } from './mvp-state.model';
import { MvpService } from '../services/mvp.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@State<MvpStateModel>({
  name: 'mvp',
})
@Injectable()
export class MvpState {
  constructor(
    private mvpService: MvpService,
    private zone: NgZone,
    private router: Router,
  ) {}

  @Action(UploadImage)
  uploadImage(
    { patchState }: StateContext<MvpStateModel>,
    { files }: UploadImage,
  ): Observable<UploadImageResponse[]> {
    return this.mvpService.uploadImage(files).pipe(
      tap((res) => {
        if (res) {
          patchState({
            imageFiles: res,
          });
        }
      }),
      catchError((err) => {
        patchState({
          imageFiles: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(GetLocation)
  getLocation(
    { patchState, dispatch }: StateContext<MvpStateModel>,
    { lat, lon }: GetLocation,
  ): Observable<any> {
    patchState({ address: undefined });
    return this.mvpService.getLocation(lat, lon).pipe(
      tap((res) => {
        if (res) {
          const address: any = res.features[0].properties.address;
          patchState({
            address: {
              street: {
                houseNumber: address.house_number,
                streetName: address.road,
              },
              city: address.city,
              country: address.country,
              postcode: address.postcode,
            },
          });
        }
      }),
      catchError((err) => {
        patchState({
          address: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(SaveProductTemporaryInfo)
  saveProductTemporaryInfo(
    { patchState, getState }: StateContext<MvpStateModel>,
    { params }: SaveProductTemporaryInfo,
  ): any {
    const temporaryProduct = getState().productTemporaryInfo;
    patchState({
      productTemporaryInfo: { ...temporaryProduct, ...params },
    });
  }

  @Action(AddProduct)
  addNftProduct(
    { patchState, dispatch }: StateContext<MvpStateModel>,
    { params }: AddProduct,
  ): Observable<any> {
    return this.mvpService.addProduct(params).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            productId: res.product_id,
          });
        }
      }),
      catchError((err) => {
        patchState({
          productId: '',
        });
        return throwError(err);
      }),
    );
  }

  @Action(AddDataForAdvertisement)
  addDataForAdvertisement(
    { patchState, dispatch, getState }: StateContext<MvpStateModel>,
    { params, user_id }: AddDataForAdvertisement,
  ): Observable<any> {
    return this.mvpService.createDataForAdvertisement(params).pipe(
      tap((res) => {
        if (res.status) {
          // const user_id = getState().customerId;
          patchState({
            advertisementData: res.details,
          });
          dispatch(new CreateAdvertisement(user_id, params.object_id));
        }
      }),
      catchError((err) => {
        patchState({
          advertisementData: '',
        });
        return throwError(err);
      }),
    );
  }

  @Action(CreateAdvertisement)
  createAdvertisement(
    { patchState, dispatch }: StateContext<MvpStateModel>,
    { user_id, object_id }: CreateAdvertisement,
  ): Observable<any> {
    return this.mvpService.createAdvertisement(user_id, object_id).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            advertisement: res.answer,
          });

          this.zone.run(() => {
            this.router.navigate(['/mvp/mvp-5']);
          });
        }
      }),
      catchError((err) => {
        patchState({
          advertisement: '',
        });
        return throwError(err);
      }),
    );
  }

  @Action(EditNftProduct)
  editNftProduct(
    { patchState }: StateContext<MvpStateModel>,
    { params, id }: EditNftProduct,
  ): Observable<any> {
    return this.mvpService.editProduct(params, id).pipe(
      tap((res) => {
        if (res.status) {
          console.log(res);
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(GetProduct)
  getShopProduct(
    { getState, setState, patchState }: StateContext<MvpStateModel>,
    { id, botId }: GetProduct,
  ) {
    return this.mvpService.getProduct(id, botId).pipe(
      tap((result) => {
        if (result.status) {
          patchState({
            product: result,
          });
        }
      }),
      catchError((err) => {
        patchState({
          product: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(GetPathname)
  getPathname(
    { patchState }: StateContext<MvpStateModel>,
    { pathname }: GetPathname,
  ): any {
    patchState({
      pathname: pathname,
    });
  }

  @Action(SetProductId)
  setProductId(
    { patchState }: StateContext<MvpStateModel>,
    { productId }: SetProductId,
  ): any {
    patchState({
      productId: productId,
    });
  }

  @Action(ClearState)
  clearState({ patchState }: StateContext<MvpStateModel>, {}: ClearState): any {
    patchState({
      productTemporaryInfo: undefined,
      product: undefined,
      productId: undefined,
      imageFiles: [],
      advertisementData: '',
      advertisement: '',
    });
  }
}
