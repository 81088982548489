import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MapService } from '../../../core/services/map.service';
import {
  DEFAULT_GEOLOCATION,
  MAP_DEFAULT_OPTIONS,
} from './map-config';
import mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { AddressSearch } from '../../../features/expose/store';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input() geolocation: AddressSearch | null = DEFAULT_GEOLOCATION;
  map!: mapboxgl.Map;
  isStylesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(private mapService: MapService) {}

  ngOnInit() {
    this.mapService.initMap(MAP_DEFAULT_OPTIONS);
    this.mapService.mapInstance.on('style.load', () => {
      this.isStylesLoaded$.next(true);
    });
    this.map = this.mapService.mapInstance;
    this.map.scrollZoom.disable();
    this.addMarker();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['geolocation'] && this.map) {
      this.addMarker();
    }
  }

  private addMarker() {
    if (this.geolocation?.lon && this.geolocation?.lat) {
      const center = { lat: this.geolocation.lat, lon: this.geolocation.lon };

      const markerElement = document.createElement('div');
      const markerImg = document.createElement('img');
      markerImg.src = '/assets/images/mapbox-icon.png';
      markerImg.style.width = '25px'; 
      markerImg.style.height = '36px'; 
      markerElement.appendChild(markerImg);

      
      const marker = new mapboxgl.Marker({
        element: markerElement,
        draggable: true,
      }).setLngLat([this.geolocation.lon, this.geolocation.lat]);
      marker.addTo(this.map);
      this.map.flyTo({ center: [this.geolocation.lon, this.geolocation.lat], zoom: 15, animate: false });
    }
  }
}
