import { AIDescriptions, ImagesPageSteps } from './expose-state.model';

export class AddParamsToDocumentTemplate {
  static readonly type = '[Expose] Add Params To Document Template';
  constructor(public settings: any) {}
}

export class SetImagesPageStep {
  static readonly type = '[Expose] Set Images Page Step';
  constructor(public step: ImagesPageSteps) {}
}

export class GetAITextForImages {
  static readonly type = '[Expose] GetAITextForImages';
  constructor(
    public language: string,
    public productId: string,
  ) {}
}

export class AddDataAfterProductCreated {
  static readonly type = '[Expose] Add Data After Product Created';
  constructor(public id: string) {}
}

export class DocumentPageState {
  static readonly type = '[Expose] Document Page State';
  constructor(public state: boolean) {}
}

export class CreateDocumentProduct {
  static readonly type = '[Expose] Create Document Product';
  constructor(public product: any) {}
}

export class SetCurrentForm {
  static readonly type = '[Expose] Set current form';
  constructor(public form: any) {}
}

export class RegenerateText {
  static readonly type = '[Expose] Regenerate Text';
  constructor(
    public imageNumber: number,
    public image: string,
    public language: string,
    public type: string,
  ) {}
}

export class GetDocumentChangesById {
  static readonly type = '[Expose] Get Document Changes By Id';
  constructor(public id: string) {}
}
export class SaveAITextsChanges {
  static readonly type = '[Expose] SaveAITextsChanges';
  constructor(public descriptions: AIDescriptions[]) {}
}

export class GetGeolocationByName {
  static readonly type = '[Expose] GetAddressByPlaceName';
  constructor(public placeName: string) {}
}

export class EditDocumentProduct {
  static readonly type = '[Expose] Edit Document Product';
  constructor(
    public params: any,
    public id: string,
  ) {}
}

export class SetDocumentProduct {
  static readonly type = '[Expose] Set Document Product';
  constructor(public product: any) {}
}

export class ClearExposeState {
  static readonly type = '[Expose] ClearExposeState';
}
export class ClearUploadedImages {
  static readonly type = '[Expose] ClearUploadedImages';
}

export class GenerateVSImage {
  static readonly type = '[Expose] GenerateVSImage';
  constructor(
    public img: string,
    public style: string,
    public roomType: string,
  ) {}
}
export class ReGenerateVSImage {
  static readonly type = '[Expose] ReGenerateVSImage';
  constructor(
    public renderId: string,
    public style: string,
    public roomType: string,
    public arrayLength: number,
  ) {}
}
export class GetGeneratedVSImages {
  static readonly type = '[Expose] GetGeneratedVSImages';
  constructor(public renderId: string) {}
}
export class ClearVSImages {
  static readonly type = '[Expose] ClearVSImages';
  constructor() {}
}
export class CreateVsService {
  static readonly type = '[Expose] Create Vs Service';
  constructor(public renderId: string) {}
}
export class ViewVsService {
  static readonly type = '[Expose] View Vs Service';
  constructor(public renderId: string) {}
}
export class ViewVsOptions {
  static readonly type = '[Expose] View Vs Options';
  constructor() {}
}

export class SelectVSImage {
  static readonly type = '[Expose] SelectVSImage';
  constructor(public img: string) {}
}

export class SaveVSImage {
  static readonly type = '[Expose] SaveVSImage';
  constructor(
    public img: string,
    public id: string,
  ) {}
}

export class UpdateSnapshotList {
  static readonly type = '[Expose] UpdateSnapshotList';
  constructor(
    public newItem: any,
    public imageNumber: number,
    public type: string,
  ) {}
}

export class ClearPageForms {
  static readonly type = '[Expose] Clear page forms';
  constructor() {}
}
export class UploadImages {
  static readonly type = '[Expose] Upload images';
  constructor(public images: any[]) {}
}

export class GetDocument {
  static readonly type = '[Expose] Get document';
  constructor(public id: string) {}
}
