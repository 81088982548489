import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { DocumentSettingsComponent } from './components/document-settings/document-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { CustomNumberFormatPipe } from './pipes/custom-number-format.pipe';
import { MapComponent } from './components/map/map.component';
import { NavigationSidebarComponent } from './components/navigation-sidebar/navigation-sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationMobileComponent } from './components/navigation-mobile/navigation-mobile.component';
import { CustomTextEditComponent } from './components/custom-text-edit/custom-text-edit.component';
import { SnackBarComponent } from './modals/snack-bar/snack-bar.component';
import { BalacePointsToUsdPipe } from './pipes/balace-points-to-usd.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { UsdToPoints } from './pipes/usd-to-points.pipe';
import { DecimalsToStrPipe } from './pipes/decimals-to-str.pipe';
import { RemoveSymbolPipe } from './pipes/remove-symbol.pipe';
import { BalacePointsToUsdOldPipe } from './pipes/balace-points-to-usd-old.pipe';

@NgModule({
  declarations: [
    SettingsMenuComponent,
    DocumentSettingsComponent,
    ProfileMenuComponent,
    CustomNumberFormatPipe,
    BalacePointsToUsdPipe,
    MapComponent,
    NavigationSidebarComponent,
    HeaderComponent,
    NavigationMobileComponent,
    SnackBarComponent,
    CustomTextEditComponent,
    FooterComponent,
    ConfirmDialogComponent,
    UsdToPoints,
    DecimalsToStrPipe,
    RemoveSymbolPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    SettingsMenuComponent,
    DocumentSettingsComponent,
    ProfileMenuComponent,
    CustomNumberFormatPipe,
    BalacePointsToUsdPipe,
    MapComponent,
    NavigationSidebarComponent,
    HeaderComponent,
    NavigationMobileComponent,
    CustomTextEditComponent,
    SnackBarComponent,
    FooterComponent,
    UsdToPoints,
    DecimalsToStrPipe,
    RemoveSymbolPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CustomNumberFormatPipe,
    BalacePointsToUsdPipe,
    BalacePointsToUsdOldPipe,
    UsdToPoints,
    DecimalsToStrPipe,
    RemoveSymbolPipe,
  ],
})
export class SharedModule {}
