import { Selector } from '@ngxs/store';
import { CoreState } from './core.state';
import {
  AllProductsI,
  ConfirmEmailResponse,
  CoreStateModel,
  FolderInterface,
  PaymentIntent,
  RegisterEmailResponse,
  ResetPasswordResponse,
  Transaction,
  UploadImageResponse,
  UserPlan,
  UserProfile,
  VerifyEmailResponse,
} from './core-state.model';
import { DocumentInterface, ExposeState } from '../../features/expose/store';
import { cloneDeep } from 'lodash';
import { ImageInfo } from '../../features/staging/store';

export class CoreSelectors {
  /* -------------------------------- Auth data ------------------------------- */

  @Selector([CoreState])
  static verificationToken(state: CoreStateModel): string {
    return state.verificationToken;
  }

  @Selector([CoreState])
  static verificationEmail(state: CoreStateModel): string {
    return state.verificationEmail;
  }

  /* -------------------------------- User data ------------------------------- */

  @Selector([CoreState])
  static userId(state: CoreStateModel): string {
    return state.userId;
  }

  @Selector([CoreState])
  static userToken(state: CoreStateModel): string {
    return state.userToken;
  }

  @Selector([CoreState])
  static userProfile(state: CoreStateModel): UserProfile {
    return state.userProfile;
  }

  /* --------------------------------- Layout --------------------------------- */

  @Selector([CoreState])
  static sidebarShrinked(state: CoreStateModel): boolean {
    return state.sidebarShrinked;
  }

  /* ------------------------------ Dashboard TMP ----------------------------- */

  @Selector([CoreState])
  static totalAds(state: CoreStateModel): number {
    return state.totalAds;
  }

  /* ----------------------------------- ++ ----------------------------------- */

  @Selector([CoreState])
  static categories(state: CoreStateModel): Array<any> {
    return state.categories;
  }

  @Selector([CoreState])
  static customer(state: CoreStateModel): string {
    return state.customerId;
  }

  @Selector([CoreState])
  static status(state: RegisterEmailResponse): boolean {
    return state.status;
  }

  @Selector([CoreState])
  static error(state: RegisterEmailResponse): string {
    return state.error;
  }

  @Selector([CoreState])
  static statusVerify(state: VerifyEmailResponse): boolean {
    return state.status;
  }

  @Selector([CoreState])
  static errorVerify(state: VerifyEmailResponse): string {
    return state.error;
  }

  @Selector([CoreState])
  static company(state: CoreStateModel): string {
    return state.company;
  }

  @Selector([CoreState])
  static address(state: CoreStateModel): string {
    return state.address;
  }

  @Selector([CoreState])
  static telephone(state: CoreStateModel): string {
    return state.telephone;
  }

  @Selector([CoreState])
  static mobile(state: CoreStateModel): string {
    return state.mobile;
  }

  @Selector([CoreState])
  static aiProductDescriptions(state: CoreStateModel): any {
    return state.aiProductDescriptions;
  }

  @Selector([CoreState])
  static products(state: CoreStateModel): any {
    return state.products;
  }

  @Selector([CoreState])
  static product(state: CoreStateModel): any {
    return state.product;
  }

  @Selector([CoreState])
  static statusConfirmEmail(state: ConfirmEmailResponse): boolean {
    return state.status;
  }

  @Selector([CoreState])
  static errorConfirmEmail(state: ConfirmEmailResponse): string {
    return state.error;
  }

  @Selector([CoreState])
  static statusResetPassword(state: ResetPasswordResponse): boolean {
    return state.status;
  }
  @Selector([CoreState])
  static transactions(state: CoreStateModel): Transaction[] {
    return state.transactions;
  }

  @Selector([CoreState])
  static paymentIntent(state: CoreStateModel): PaymentIntent {
    return state.paymentIntent;
  }
  @Selector([CoreState])
  static userPlan(state: CoreStateModel): UserPlan {
    return state.userPlan;
  }
  @Selector([CoreState])
  static paymentModalStatus(state: CoreStateModel): boolean {
    return state.paymentModalStatus;
  }

  @Selector([CoreState])
  static stagingProduct(state: CoreStateModel): DocumentInterface {
    return state.stagingProduct;
  }

  @Selector([CoreState])
  static selectedFolder(state: CoreStateModel): string {
    return state.selectedFolder;
  }
  @Selector([CoreState])
  static folders(state: CoreStateModel): FolderInterface[] {
    return state.folders;
  }
  @Selector([CoreState])
  static trashbinFolder(state: CoreStateModel): FolderInterface {
    return state.trashbinFolder;
  }

  @Selector([CoreState])
  static allStagingProducts(state: CoreStateModel): AllProductsI {
    const images = [];
    let stagingImgs: ImageInfo[] = cloneDeep(
      state.stagingProduct.attributes.staging_images,
    );
    stagingImgs = stagingImgs.map((img) => {
      return { ...img, product_id: state.stagingProduct.product_id };
    });
    images.push(...stagingImgs);
    state.folders.forEach((folder) => {
      let folderImgs = cloneDeep(folder.attributes.staging_images);
      folderImgs = folderImgs.map((img) => ({
        ...img,
        product_id: folder.product_id,
      }));
      images.push(...folderImgs);
    });

    return {
      products: [state.stagingProduct, ...state.folders],
      images: images,
      product_id: 'all_products',
    };
  }

  @Selector([CoreState])
  static url(state: UploadImageResponse): string {
    return state.url;
  }
}
