<div class="snack-bar">
  <img
    *ngIf="type === 'success'"
    src="assets/images/icons/icon-success.svg"
    alt="success"
  />
  <img
    *ngIf="type === 'error'"
    src="assets/images/icons/alert-triangle.svg"
    alt="error"
  />
  <p>{{ message }}</p>
  <a [routerLink]="link.url"
     style="text-decoration: underline;"
     *ngIf="link?.url"
  >{{link.text}}</a>
<!--  <span (click)="snackBarRef.dismissWithAction()">hide</span>-->
</div>
