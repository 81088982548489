import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { CoreStateModel, LogOut } from '../store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const userToken =
      this.store.selectSnapshot<string>((state: { core: CoreStateModel }) => {
        return state.core.userToken;
      }) || '';
    const customToken = req.headers.get('X-Access-Token');
    const request = req.clone({
      setHeaders: {
        'X-Access-Token': customToken || userToken,
      },
    });
    return next.handle(request).pipe(
      catchError((e) => {
        return this.handleAuthError(e);
      }),
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (
      err.status === 401 ||
      (err.status === 400 &&
        (err.error.error === 'You have no rights in this bot' ||
          err.error.error === 'Access denied'))
    ) {
      this.store.dispatch(new LogOut());
    }

    return throwError(() => err);
  }
}
