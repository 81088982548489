import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);
  message =
    this.snackBarRef.containerInstance.snackBarConfig.announcementMessage;
  type: 'info' | 'error' | 'success' =
    this.snackBarRef.containerInstance.snackBarConfig.data.type;
  link: { text: string; url: string } =
    this.snackBarRef.containerInstance.snackBarConfig.data.link;
  constructor() {}

  ngOnInit(): void {}
}
