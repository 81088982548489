import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointsToUsdOld',
})
export class BalacePointsToUsdOldPipe implements PipeTransform {
  transform(value: string | null): number {
    if (!value) {
      return 0;
    }
    const points = Number(value);

    if (points < 50) {
      return points * 22;
    } else if (points >= 50 && points < 100) {
      return points * 17;
    } else if (points >= 100) {
      return points * 15;
    } else {
      return points * 22;
    }
  }
}
