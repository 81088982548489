<div class="navigation-mobile" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'main'">
    <div class="navigation__menu">
      <button
        mat-icon-button
        (click)="$event.stopPropagation()"
        class="mobile-sidebar__menu"
        [matMenuTriggerFor]="menu"
        aria-label="Page menu"
      >
        <mat-icon svgIcon="menu-icon"></mat-icon>
      </button>
    </div>
    <div
      *ngIf="title"
      class="navigation-mobile__title title-expose title-expose--size-14"
    >
      {{ title }}
    </div>
    <div class="navigation-mobile__controls">
      <!-- <span class="notification">-->
      <!--   <b class="notification__count"> 1 </b>-->
      <!--   <i class="notification__icon">-->
      <!--     <mat-icon [svgIcon]="'notification-icon'"></mat-icon>-->
      <!--   </i>-->
      <!-- </span>-->
      <span class="profile profile__balance" [matMenuTriggerFor]="balanceMenu">
        <b>{{ userPlan.balance }}</b>
      </span>
      <span class="profile" [matMenuTriggerFor]="profileMenu">
        <b>{{ userNameShort }}</b>
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'alt'">
    <div class="navigation-mobile__back-link" [routerLink]="returnLink">
      <i class="link__icon">
        <mat-icon [svgIcon]="'chevron-left'"></mat-icon>
      </i>
      <span class="link__title">
        {{ returnTitle || "" | translate }}
      </span>
    </div>
  </ng-container>
</div>

<mat-menu #menu="matMenu" yPosition="below">
  <ul class="navigation-sidebar__links navigation-sidebar__links--mobile">
    <li class="navigation-sidebar__link" routerLinkActive="active">
      <a routerLink="/dashboard">
        <i>
          <mat-icon [svgIcon]="'dashboard-icon'"></mat-icon>
        </i>
        <span>{{ "Dashboard" | translate }}</span>
      </a>
    </li>
    <!--    <li class="navigation-sidebar__link" routerLinkActive="active">-->
    <!--      <a routerLink="/mvp">-->
    <!--        <i>-->
    <!--          <mat-icon [svgIcon]="'immobilien-icon'"></mat-icon>-->
    <!--        </i>-->
    <!--        <span> EasyMaklerIO </span>-->
    <!--      </a>-->
    <!--    </li>-->
    <!--    <li class="navigation-sidebar__link" routerLinkActive="active">-->
    <!--      <a routerLink="/contacts">-->
    <!--        <i>-->
    <!--          <mat-icon [svgIcon]="'kontakte-icon'"></mat-icon>-->
    <!--        </i>-->
    <!--        <span>{{ "Kontakte" | translate }}</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/expose/property">
        <i>
          <mat-icon [svgIcon]="'immobilien-icon'"></mat-icon>
        </i>
        <span>{{ "Exposés" | translate }}</span>
      </a>
    </li>

    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/staging">
        <i>
          <mat-icon>add_photo_alternate</mat-icon>
        </i>
        <span>{{ "Staging" | translate }}</span>
      </a>
    </li>
    <!--    <li-->
    <!--      class="navigation-sidebar__link"-->
    <!--      routerLinkActive="active"-->
    <!--      [routerLinkActiveOptions]="{ exact: true }"-->
    <!--    >-->
    <!--      <a routerLink="/mail">-->
    <!--        <i>-->
    <!--          <mat-icon [svgIcon]="'mail-icon'"></mat-icon>-->
    <!--        </i>-->
    <!--        <span>{{ "Nachrichten" | translate }}</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <!--    <li class="navigation-sidebar__link" routerLinkActive="active">-->
    <!--      <a routerLink="/calendar">-->
    <!--        <i>-->
    <!--          <mat-icon [svgIcon]="'calendar-icon'"></mat-icon>-->
    <!--        </i>-->
    <!--        <span>{{ "Kalender" | translate }}</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/payments">
        <i>
          <mat-icon>payment</mat-icon>
        </i>
        <span>{{ "payments.title" | translate }}</span>
      </a>
    </li>
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/">
        <i>
          <mat-icon [svgIcon]="'chat-icon'"></mat-icon>
        </i>
        <span>{{ "Hilfe Assistent" | translate }}</span>
      </a>
    </li>
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/settings">
        <i>
          <mat-icon [svgIcon]="'settings-icon'"></mat-icon>
        </i>
        <span>{{ "Einstellungen" | translate }}</span>
      </a>
    </li>
  </ul>
</mat-menu>

<mat-menu #profileMenu="matMenu" yPosition="below" class="mat-menu-wide">
  <div class="profile-modal">
    <div class="profile-modal__user">
      <div class="user__avatar">
        <img
          *ngIf="userProfile.image"
          src="{{ userProfile.image }}"
          [alt]="userNameShort"
        />
        <span *ngIf="!userProfile.image">
          <b>{{ userNameShort }}</b>
        </span>
      </div>
      <div class="user__info">
        <h3>{{ userProfile.name }}</h3>
        <p>{{ userProfile.company }}</p>
      </div>
    </div>
    <ul class="profile-modal__menu">
      <li [routerLink]="'/onboarding/personal-data'">
        {{ "profile.modal.manage" | translate }}
      </li>
      <li (click)="logOut()">{{ "profile.modal.logout" | translate }}</li>
      <!-- Tmp before profile page -->
      <li mat-menu-item [matMenuTriggerFor]="languages">
        {{ "base.text=language" | translate }}
      </li>
    </ul>
  </div>
</mat-menu>

<mat-menu #languages="matMenu">
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'en'"
    (click)="changeLanguage('en')"
  >
    EN
  </button>
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'de'"
    (click)="changeLanguage('de')"
  >
    DE
  </button>
</mat-menu>
<mat-menu #balanceMenu="matMenu">
  <div class="profile-modal">
    <div class="profile-modal__user">
      <div class="user__avatar user__avatar--grey">
        <span *ngIf="!userProfile.image">
          <b>{{ userPlan.balance }}</b>
        </span>
      </div>
      <div class="user__info">
        <h3 translate>Credits verfügbar</h3>
        <div class="user__links">
          <a routerLink="/payments/checkout" translate>Kaufe mehr Credits</a>
          <a routerLink="/payments" translate>Transaktionshistorie</a>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
