import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ClearCoreState,
  CoreSelectors,
  UpdateUserProfile,
  UserProfile,
} from '../../../core/store';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { ClearExposeState } from '../../../features/expose/store';

@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  styleUrls: ['./document-settings.component.scss'],
})
export class DocumentSettingsComponent {
  @ViewSelectSnapshot(CoreSelectors.userProfile)
  userProfile!: UserProfile;
  @Output() selectMenuItem = new EventEmitter<{ item: string; id: number }>();
  @Output() returnEvent = new EventEmitter<null>();
  menuItems = [
    { title: 'Titel', isActive: true },
    { title: 'Ansprechpartner', isActive: false },
    { title: 'Inhaltsverzeichnis', isActive: false },
    { title: 'Zusammenfassung', isActive: false },
    { title: 'Gebäudeplan', isActive: false },
    { title: 'Beschreibung', isActive: false },
    { title: 'Standort / Karte', isActive: false },
    { title: 'Besonderheiten', isActive: false },
    { title: 'Bilder', isActive: false },
    { title: 'Andere Informationen', isActive: false },
  ];

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
  ) {}

  onSelectTab(i: number) {
    this.menuItems.map((item, index) => {
      item.isActive = i === index;
      if (item.isActive) {
        this.selectMenuItem.emit({ item: item.title, id: index });
      }
      return item;
    });
  }

  get currentLanguage(): string {
    return this.translate.currentLang;
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
    const profile = this.userProfile;
    profile.language = language;
    this.store.dispatch(new UpdateUserProfile(profile));
  }

  onBackToExposes() {
    this.returnEvent.emit();
    this.store.dispatch(new ClearCoreState());
    this.store.dispatch(new ClearExposeState()).subscribe((res) => {
      setTimeout(() => {
        this.router.navigate(['/expose/property']);
      }, 1000);
    });
  }

  onReturn() {
    this.returnEvent.emit();
    this.store.dispatch(new ClearCoreState());
    this.store.dispatch(new ClearExposeState()).subscribe((res) => {
      setTimeout(() => {
        this.router.navigate(['/expose/steps/step-5']);
      }, 1000);
    });
  }
}
