import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, last, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { UploadImageResponse } from '../store';

export interface FileUploadStatus {
  status?: 'upload' | 'complete' | 'error';
  progress?: number;
  fileName?: string;
  response?: any;
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService,
  ) {}

  uploadFile(type: string, file: any): Observable<FileUploadStatus> {
    const data = new FormData();
    data.append('file', file);

    return this.http
      .post(
        `${environment.apiUrl}/attachment/${type}/${environment.botId}`,
        data,
        {
          reportProgress: true,
          observe: 'events',
        },
      )
      .pipe(
        map((event) => this.getEventMessage(event, file)),
        catchError((e) => {
          this.notification.error(e.error.error);
          return of(this.onError());
        }),
      );
  }

  private getEventMessage(event: HttpEvent<any>, file: File): FileUploadStatus {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          status: 'upload',
          fileName: file.name,
          progress: 1,
        };

      case HttpEventType.UploadProgress:
        const percentDone = event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0;
        return {
          status: 'upload',
          fileName: file.name,
          progress: percentDone,
        };

      case HttpEventType.Response:
        return {
          status: 'complete',
          fileName: file.name,
          progress: 100,
          response: event.body,
        };

      default:
        return {
          status: undefined,
        };
    }
  }

  private onError(): FileUploadStatus {
    return {
      status: 'error',
    };
  }

  uploadImage(file: FormData): Observable<UploadImageResponse> {
    return this.http.post<UploadImageResponse>(
      `${environment.apiUrl}/attachment/image/${environment.botId}`,
      file,
    );
  }
}
