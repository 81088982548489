import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberFormat',
})
export class CustomNumberFormatPipe implements PipeTransform {
  transform(value: string): string | undefined {
    const regex = /^\d+(,\d*)?$/;
    if (!regex.test(value)) {
      return value;
    }

    const [whole, fractional = null] = value.split(',');

    if (fractional === null) {
      return `${whole},00`;
    }

    if (fractional.length === 1) {
      return `${whole},${fractional}0`;
    }

    if (fractional.length === 2) {
      return `${whole},${fractional}`;
    }

    if (fractional.length >= 2) {
      return `${whole},${fractional.slice(0, 2)}`;
    }

    return;
  }
}
