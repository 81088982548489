import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointsToUsd',
})
export class BalacePointsToUsdPipe implements PipeTransform {
  transform(value: string | null): number {
    if (!value) {
      return 0;
    }
    const points = Number(value);

    if (points < 50) {
      return points * 12.5;
    } else if (points >= 50 && points < 100) {
      return points * 10.5;
    } else if (points >= 100) {
      return points * 9;
    } else {
      return points * 12.5;
    }
  }
}
