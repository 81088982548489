<nav class="navigation-sidebar">
  <div class="navigation-sidebar__controls">
    <span class="app-button app-button--small" (click)="toggleSidebar()">
      <mat-icon svgIcon="back-icon-expose" *ngIf="!sidebarShrinked"></mat-icon>
      <mat-icon svgIcon="expand" *ngIf="sidebarShrinked"></mat-icon>
    </span>
  </div>
  <ul class="navigation-sidebar__links">
    <li class="navigation-sidebar__link" routerLinkActive="active">
      <a routerLink="/dashboard">
        <i>
          <mat-icon [svgIcon]="'dashboard-icon'"></mat-icon>
        </i>
        <span>{{ "Dashboard" | translate }}</span>
      </a>
    </li>
<!--    <li class="navigation-sidebar__link" routerLinkActive="active">-->
<!--      <a routerLink="/mvp">-->
<!--        <i>-->
<!--          <mat-icon [svgIcon]="'immobilien-icon'"></mat-icon>-->
<!--        </i>-->
<!--        <span> EasyMaklerIO </span>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="navigation-sidebar__link" routerLinkActive="active">-->
<!--      <a routerLink="/contacts">-->
<!--        <i>-->
<!--          <mat-icon [svgIcon]="'kontakte-icon'"></mat-icon>-->
<!--        </i>-->
<!--        <span>{{ "Kontakte" | translate }}</span>-->
<!--      </a>-->
<!--    </li>-->
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/expose/property">
        <i>
          <mat-icon [svgIcon]="'immobilien-icon'"></mat-icon>
        </i>
        <span>{{ "Exposés" | translate }}</span>
      </a>
    </li>
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/staging">
        <i>
          <mat-icon>add_photo_alternate</mat-icon>
        </i>
        <span>{{ "Staging" | translate }}</span>
      </a>
    </li>
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/payments">
        <i>
          <mat-icon>payment</mat-icon>
        </i>
        <span>{{ "payments.title" | translate }}</span>
      </a>
    </li>
  </ul>
  <ul class="navigation-sidebar__links navigation-sidebar__links--bottom" *ngIf="true">
<!--    <li-->
<!--      class="navigation-sidebar__link"-->
<!--      routerLinkActive="active"-->
<!--      [routerLinkActiveOptions]="{ exact: true }"-->
<!--    >-->
<!--      <a routerLink="/">-->
<!--        <i>-->
<!--          <mat-icon [svgIcon]="'chat-icon'"></mat-icon>-->
<!--        </i>-->
<!--        <span>{{ "Hilfe Assistent" | translate }}</span>-->
<!--      </a>-->
<!--    </li>-->
    <li
      class="navigation-sidebar__link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a routerLink="/settings">
        <i>
          <mat-icon [svgIcon]="'settings-icon'"></mat-icon>
        </i>
        <span>{{ "Einstellungen" | translate }}</span>
      </a>
    </li>
  </ul>
</nav>
