import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent {
  menuItems = [
    { icon: 'dashboard-icon', title: 'Dashboard', isActive: false },
    // { icon: 'immobilien-icon', title: 'EasyMaklerIO', isActive: false },
    // { icon: 'kontakte-icon', title: 'Kontakte', isActive: false },
    { icon: 'immobilien-icon', title: 'Exposés', isActive: true },
    // { icon: 'mail-icon', title: 'Nachrichten', isActive: false },
    { icon: 'calendar-icon', title: 'Transactions', isActive: false },
  ];

  bottomMenuItems = [
    { icon: 'chat-icon', title: 'Hilfe Assistent' },
    { icon: 'settings-icon', title: 'Einstellungen' },
  ];

  constructor(private router: Router) {}

  onSelectTab(i: number) {
    if (i === 0) {
      this.router.navigate(['/dashboard']);
    }
    if (i === 1) {
      this.router.navigate(['/mvp/mvp-1']);
    }
    if (i === 2) {
      this.router.navigate(['/payments']);
    }
    this.menuItems.map((item, index) => {
      if (i === index) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
  }

  onSelectSettings(i: number) {}
}
