import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CoreService } from '../services/core.service';

export const authGuard: CanActivateFn = () => {
  const router: Router = inject(Router);
  const canActivate = inject(CoreService).canActivate();
  if (canActivate) {
    return true;
  } else {
    return router.navigate(['/onboarding']);
  }
};

export const onboardingGuard: CanActivateFn = () => {
  const router: Router = inject(Router);
  const canActivate = inject(CoreService).canActivate();
  if (!canActivate) {
    return true;
  } else {
    // return false;
    return router.navigate(['/dashboard']);
  }
};
