// MAP CONSTANTS

export const ZOOM = {
  MinZoom: 1,
  MaxZoom: 20,
  DefaultZoom: 10,
};

export const DEFAULT_GEOLOCATION = {
  lat: 52.5170365,
  lon: 13.3888599,
  name: 'Berlin',
  display_name: 'Berlin, Germany',
};

export const START_LOCATION = [13.24, 52.3];

export const STYLES = {
  streets: 'mapbox://styles/mapbox/streets-v11',
  terrain: 'mapbox://styles/mapbox/outdoors-v11',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
};
export const MAP_DEFAULT_OPTIONS: MapOptions = {
  container: 'map',
  style: STYLES.streets,
  center: START_LOCATION,
  zoom: ZOOM.DefaultZoom,
  dragRotate: false,
  panToOptions: false,
  movingOptions: false,
  preserveDrawingBuffer: true,
  dragPan: false,
};

// MAP INTERFACES
export interface MapOptions {
  style: string;
  center: number[];
  zoom: number;
  container: string;
  dragRotate: boolean;
  panToOptions: boolean;
  movingOptions: boolean;
  preserveDrawingBuffer: boolean;
  dragPan: boolean;
}

export interface SourceInterface {
  type: string;
  tiles: string[];
  tileSize: number;
  encoding?: string;
  minzoom?: number;
  maxzoom?: number;
}

export interface LayerInterface {
  id: string;
  source: SourceInterface;
  minzoom: number;
  maxzoom: number;
  type: string;
  layout?: any;
  paint?: any;
}

export interface MarkerI {
  state: boolean;
  lngLat: { lng: number; lat: number };
}

export interface Marker {
  state: boolean;
  lngLat: number[];
}

export interface MarkerOptions {
  offset: number[];
  anchor: string;
  draggable: boolean;
  element: HTMLElement;
  lngLat: number[];
  id?: number;
  group?: string;
}
