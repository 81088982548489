<div class="footer">
  <div class="footer-container">
    <div class="footer-container__info-col footer-container__info-col--first">
     <div class="footer-container__title">TheRealty.ai</div>
      <div class="footer-container__description">
        {{'TheRealty.ai ist eine Spezial-Agentur, die sich auf' | translate}} <br>
        {{'das digitale Staging von Immobilien fokussiert.' | translate}} <br>
        {{'Durch diese virtuellen Umsetzungen können Kosten' | translate }}<br>
        {{'und Zeit extrem reduziert werden. Mit nur wenigen' | translate}} <br>
        {{'Klicks können Sie atemberaubende und' | translate}} <br>
        {{'verkaufsfördernde Werbebilder einer Immobilie erstellen.' | translate}}
      </div>
    </div>
    <div class="footer-container__info-col" style="width: 30%">
      <div class="footer-container__title">HOMESTAIGING</div>
      <div class="footer-container__description
      footer-container__description--clickable"
           [routerLink]="'/payments/checkout'" translate>Preise</div>
      <div class="footer-container__description
      footer-container__description--clickable"
           [routerLink]="'/onboarding/login'">Login</div>
      </div>
    <div class="footer-container__info-col ">
      <div class="footer-container__title" translate>ALLGEMEINES</div>
      <a class="footer-container__description footer-container__description--clickable"
         href="mailto:info@therealty.ai">Email</a>
      <a class="footer-container__description footer-container__description--clickable"
         href="https://therealty.ai/kontakt/"  target="_blank" translate>Kontakt</a>
      <a class="footer-container__description footer-container__description--clickable"
         href="https://therealty.ai/kontakt/"  target="_blank" translate>Kontakt</a>
      <a class="footer-container__description footer-container__description--clickable"
         href="https://therealty.ai/impressum/" target="_blank" translate>Impressum</a>
      <a class="footer-container__description footer-container__description--clickable"
           href="https://therealty.ai/datenschutz/" target="_blank" translate>Datenschutz</a>
      <a class="footer-container__description footer-container__description--clickable"
           href="https://therealty.ai/agbs/" target="_blank" translate>AGBs</a>
      <a class="footer-container__description footer-container__description--clickable"
           href="https://therealty.ai" target="_blank">Home</a>
    </div>
  </div>
  <div class="footer-container footer-container__rights">
    <div class="footer-container__description ">© 2024 TheRealty.ai</div>
  </div>
</div>
