import { BaseResponse } from 'src/app/shared/models/shared.model';
import { DocumentInterface, ImageInfo } from '../../features/expose/store';

export interface CoreStateModel {
  categories: Array<any>;
  customerId: string;
  customerToken: string;

  company: string;
  address: string;
  telephone: string;
  mobile: string;
  aiProductDescriptions: any;
  products: any[];
  product: any;

  //
  userToken: string;
  userId: string;
  userProfile: UserProfile;
  userPlan: UserPlan;
  // Email verification
  verificationToken: string;
  verificationEmail: string;

  // Layout

  sidebarShrinked: boolean;

  // tmp dashboard
  totalAds: number;
  paymentIntent: PaymentIntent;
  transactions: Transaction[];
  stagingProduct: DocumentInterface;
  folders: FolderInterface[];
  selectedFolder: string;
  trashbinFolder: FolderInterface;
  paymentModalStatus: boolean;
}

export interface FolderInterface {
  name: string;
  image?: string;
  price?: number;
  created?: Date;
  product_id?: string;
  category_id: string;
  bot_id: string;
  attributes: FolderAttributes;
}

export interface FolderAttributes {
  staging_images: ImageInfo[];
  trashbin_status: boolean;
  name: string;
}

export interface VerifyEmailResponse {
  hash: string;
  status: boolean;
  error: string;
  email: string;
}

export interface RegisterEmailResponse {
  status: boolean;
  error: string;
}

export interface MapOptions {
  style: mapboxgl.Style | string | undefined;
  center: any;
  zoom: number | undefined;
  container: string | HTMLElement;
  dragRotate: boolean | undefined;
}

export interface ConfirmEmailRequest {
  email: string;
}

export interface ConfirmEmailResponse {
  status: boolean;
  error: string;
}

export interface ResetPasswordRequest {
  token: string;
  new_password: string;
  confirm_password: string;
}

export interface ResetPasswordResponse {
  status: boolean;
}

/* ---------------------------------- Personal Data ---------------------------------- */

export interface GetPersonalDataResponse {
  status: boolean;
  profile: {
    company: string;
    address: string;
    telephone: string;
    mobile: string;
    vs_retries: string;
  };
}

export interface UpdatePersonalDataRequest {
  profile: {
    company: string;
    address: string;
    telephone: string;
    mobile: string;
    vs_retries: string;
  };
}

/* ---------------------------------- User ---------------------------------- */

export interface UserProfile {
  name: string;
  image: string;
  email: string;
  vs_retries: number;
  company: string;
  address: string;
  mobile: string;
  telephone: string;
  language: string;
  company_info: string;
  expose_logotypes: {
    default: boolean;
    logo: string;
  }[];
  colors: {
    [key: string]: string;
  };
  fonts: {
    [key: string]: string;
  };
}

export interface UserPlan {
  balance: number;
  expires: Date;
  mode: string;
  status: string;
}

export interface UserProfileResponse extends BaseResponse {
  bot_id: string;
  merchant_id: string;
  name: string;
  username: string;
  login: string;
  profile: UserProfile;
  plan: UserPlan;
}

/* ---------------------------------- Auth ---------------------------------- */

export interface UserRegistrationData {
  login: string;
  password: string;
  profile: {
    name: string;
    language: string;
    // email: string;
  };
}

export interface CreateUserResponse extends BaseResponse {
  merchant_id: string;
  token: string;
}

export interface UserLoginData {
  login: string;
  password: string;
}

export interface LoginUserResponse extends BaseResponse {
  merchant_id: string;
  token: string;
}

export interface PersonData {
  merchant: {
    merchant_id: string;
  };
  profile: {
    email: string;
  };
}

export interface PersonDataResponse extends BaseResponse {
  person: PersonData;
}

export interface PaymentIntent {
  data: {
    payment_intent_id: string;
    auto_payment: boolean;
    client_secret: string;
  };
}

export interface Transaction {
  id: string;
  date: Date;
  type: string;
  amount: number;
  description: string;
  data: {
    status: string;
    source: string;
  };
  owner: {
    id: string;
    entity: string;
  };
  portal_id: string;
  bot_id: string;
}

export interface AllProductsI {
  images: ImageInfo[];
  products: (DocumentInterface | FolderInterface)[];
  product_id: string;
}

export interface UploadImageResponse {
  status: boolean;
  name: string;
  url: string;
  id: string;
}
