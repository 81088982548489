export class Config {
  public static readonly mapKey =
    'pk.eyJ1IjoibmlrYS1kZXYtYWNjb3VudCIsImEiOiJjbHA3Mmkzc3IyMHowMmpxeWFjdzlncDY5In0.jMB03w8DpWrmqwmynWHSgw';
  public static readonly stripePublishableKey =
    'pk_test_51PFwyhGDiA59jwO5V9nu6Wh13N3BGWeIKXHuOUGMaOOkUTIbQkuQz40oSOF86P4sDbMGwbpN67eABkbhKoKhkdfn00esenXTAT';
  public static readonly defaultCategory = 'ZJ3WITLOW4';
  public static readonly creditsPrices = [
    { credit: 50, price: 10.5 },
    { credit: 100, price: 9 },
  ];
  public static readonly imgCompression = 95;
  public static readonly imgWithInfoLast = 7;

  public static readonly RoomsParams = [
    // {
    //   roomType: 'Wohnzimmer',
    //   attribute: 'third_page_forms',
    //   subAttributes: ['living_rooms_count'],
    // },
    {
      roomType: 'Küche',
      attribute: 'third_page_forms',
      subAttributes: ['kitchen'],
    },
    // {
    //   roomType: 'Schlafzimmer',
    //   attribute: 'third_page_forms',
    //   subAttributes: ['bedrooms_count'],
    // },
    // {
    //   roomType: 'Badzimmer',
    //   attribute: 'third_page_forms',
    //   subAttributes: ['bathrooms_count'],
    // },
    {
      roomType: 'Balkon',
      attribute: 'second_page_form',
      subAttributes: ['balcony_orientation', 'balcony_space'],
    },
    {
      roomType: 'Terrasse',
      attribute: 'second_page_form',
      subAttributes: ['terrace_orientation', 'terrace_area'],
    },
    {
      roomType: 'Parkplatz',
      attribute: 'second_page_form',
      subAttributes: ['parking_spaces_count', 'parking_space', 'parking_costs'],
    },
  ];
}
