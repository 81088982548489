<div class="confirm-dialog">
  <div class="confirm-dialog__container">
    <div class="confirm-dialog__header" translate>
      Auf app.therealty.ai wird Folgendes angezeigt:
    </div>
    <div class="confirm-dialog__text">{{ data.question }}</div>
    <div class="confirm-dialog__btns">
      <button
        class="confirm-dialog__btn-cancel"
        translate
        (click)="close(false)"
      >
        Abbrechen
      </button>
      <button class="confirm-dialog__btn-ok" (click)="close(true)">OK</button>
    </div>
  </div>
</div>
