import { Component, OnInit } from '@angular/core';
import { IconService } from './core/services/icon.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CoreSelectors, GetProducts, SetCustomer } from './core/store';
import { GetProduct } from './features/mvp/store';
import { SetDocumentProduct } from './features/expose/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  private subscription = new Subscription();
  @Select(CoreSelectors.product) product$!: Observable<any>;

  constructor(
    public iconService: IconService,
    public translate: TranslateService,
    private store: Store,
  ) {
    translate.setDefaultLang('en');

    const savedLanguage = localStorage.getItem('language');
    savedLanguage ? translate.use(savedLanguage) : translate.use('de');
  }

  ngOnInit() {}
}
