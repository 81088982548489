export interface MvpStateModel {
  productId: string;
  advertisement: string;
  advertisementData: string;
  productTemporaryInfo: MvpProductAttributes;
  categories: Array<any>;

  imageFiles: any[];
  address: {
    street: {
      houseNumber: string;
      streetName: string;
    };
    city: string;
    country: string;
    postcode: string;
  };
  product: Product;
  pathname: string;
}

export interface CreateCustomerResponse {
  status: boolean;
  customer_id: string;
  token: string;
  error: string;
}

export interface UploadImageResponse {
  status: boolean;
  name: string;
  url: string;
  id: string;
}

export interface MvpProductAttributes {
  address: string;
  propertytype: string;
  description: string;
  price: number;
  mvpinfo: {
    address: string;
    propertytype: string;
    description: string;
    price: string;
    customer_id: string;
  };
  mvpimages: string[];
}

export interface Product {
  name: string;
  image: string;
  price: number;
  created: Date;
  product_id: string;
  category_id: string;
  bot_id: string;
  attributes: MvpProductAttributes;
}
