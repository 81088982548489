export interface ExposeStateModel {
  documentTemplate: DocumentAttributes;
  imagesPageStep: ImagesPageSteps;
  imagesDescriptions: AIDescriptions[];
  documentPageState: boolean;
  document: DocumentInterface;
  documentCreatingError: string;
  currentForm: any;
  aiSnapshotList: AISnapshotList;
  geolocation: AddressSearch;
  vsGeneratedImages: string[];
  vsSelectedImage: string;
  vsImagesStyles: string[];
  vsImagesRoomTypes: string[];
  vsRenderId: string;
  vsOptions: { styles: string[]; room_types: string[] };
  geolocationAll: AddressSearch[];
  error: boolean;
  uploadedImages: string[];
}

export interface DocumentInterface {
  name: string;
  image?: string;
  price?: number;
  created?: Date;
  product_id?: string;
  category_id: string;
  bot_id: string;
  attributes: DocumentAttributes;
}
export const DocumentAttributesVoid = {
  general_info: undefined,
  customer_id: '',
  expose_images: undefined,
  staging_images: undefined,
  staging_status: undefined,
  first_page_forms: {
    object_type: undefined,
    address: undefined,
    marketing_type: undefined,
    floor: undefined,
    floors_count: undefined,
    type_of_use: undefined,
    available_from: undefined,
    location_key_points: undefined,
    contact_person: undefined,
  },
  second_page_form: {
    rental_price: undefined,
    additional_costs: undefined,
    percent: undefined,
    active_commission: undefined,
    price: undefined,
    active_price: undefined,
    room: undefined,
    rooms_count: undefined,
    plot_size: undefined,
    living_space: undefined,
    usable_area: undefined,
  },
  third_page_forms: {
    type_of_heating: undefined,
    floor: undefined,
    bathroom: undefined,
    bathrooms_count: undefined,
    kitchen: undefined,
    kitchens_active: undefined,
    bedroom: undefined,
    bedrooms_count: undefined,
    living_room: undefined,
    living_rooms_count: undefined,
    parking_space: undefined,
    parking_spaces_count: undefined,
    basement: undefined,
    basements_count: undefined,
    special_features: undefined,
  },
  fourth_page_forms: {
    energy_efficiency_class: undefined,
    construction_year: undefined,
    energy_requirements: undefined,
    energy_certificate: undefined,
    energy_certificate_valid_until: undefined,
  },
};

export interface DocumentAttributes {
  general_info: any;
  customer_id: string;
  expose_images: ImageInfo[];
  staging_images: ImageInfo[];
  staging_status: boolean;
  name?: string;
  additional_info?: string;
  additional_section_title?: string;
  additional_section_info?: string;
  product_description: { title: string; answer: string };
  first_page_forms: FirstPageAttributes;
  second_page_form: SecondPageAttributes;
  third_page_forms: ThirdPageAttributes;
  fourth_page_forms: FourthPageForm;
  furnishing_custom_info: string;
  location_custom_info: string;
}

export interface FourthPageForm {
  energy_efficiency_class: string;
  construction_year: number;
  energy_requirements: string;
  energy_certificate: string;
  energy_certificate_valid_until: string;
}

export interface ThirdPageAttributes {
  type_of_heating: string;
  type_of_heating_other: string;
  floor: string;
  floor_other: string;
  bathroom: string;
  bathrooms_count: number;
  kitchen: string;
  kitchens_active: boolean;
  bedroom: string;
  bedrooms_count: number;
  living_room: string;
  living_rooms_count: number;
  parking_space: string;
  parking_spaces_count: number;
  basement: string;
  basements_count: number;
  special_features: string;
  condition_object: string;
  elevator: string;
  elevator_active: boolean;
  barrier: string;
  barrier_active: boolean;
  garden: string;
  garden_active: boolean;
  pet: string;
  pet_active: boolean;
  light: string;
  light_active: boolean;
}

export interface SecondPageAttributes {
  rental_price: string;
  additional_costs: string;
  percent: string;
  active_commission: boolean;
  price: string;
  active_price: boolean;
  heating: string;
  active_heating: boolean;
  security_deposit: string;
  room: string;
  rooms_count: number;
  plot_size: string;
  living_space: string;
  usable_area: string;
  balcony_available: string;
  active_balcony: boolean;
  terrace_available: string;
  active_terrace: boolean;
  balcony_space: string;
  terrace_area: string;
  parking_space: string;
  parking_costs: string;
  balcony_orientation: string;
  terrace_orientation: string;
  active_parking: boolean;
  commission: string;
}
export interface FirstPageAttributes {
  title: string;
  object_type: string;
  address: string;
  address_name: string;
  marketing_type: string;
  floor: string;
  floors_count: undefined;
  type_of_use: string;
  available_from: string;
  location_key_points: string;
  contact_person: string;
  is_furnished: boolean;
}

export interface ImagesPageSteps {
  step: string; //'add-images' | 'add-categories' | 'final' | 'completed';
}

export interface ImageInfo {
  image: string;
  category: string;
  title?: string;
  answer?: string;
  vs_retries?: number;
  render_id?: string;
  decluttered_img?: string;
}

export interface AIDescriptions {
  answer: string;
  details?: string[];
  status?: boolean;
  title: string;
}

export interface aiTextValue {
  data: string;
  type: string;
  id: number;
}

export interface AISnapshotI {
  value: aiTextValue;
}
export class AISnapshot implements AISnapshotI {
  constructor(public value: aiTextValue) {}
}

export interface AISnapshotList {
  HEAD: string | null;
  TAIL: string | null;
  CURRENT: string | null;
  SAVED: string | null;
  list: { [key: string]: AISnapshot };
}
export class AISnapshots implements AISnapshotList {
  /**
   *
   * @param HEAD - contain id of the first snapshot that was added (by default null);
   * @param TAIL - contain id of the last snapshot that was added (by default null);
   * @param CURRENT - contain id of last snapshot that was selected by user;
   * @param SAVED - contain id of the last saved snapshot to db;
   * @param list - list of snapshot
   */
  constructor(
    public HEAD: string | null = null,
    public TAIL: string | null = null,
    public CURRENT: string | null = null,
    public SAVED: string | null = null,
    public list: { [key: string]: AISnapshot } = {},
  ) {}
}

export interface AddressSearch {
  name: string;
  // boundingbox: string[] | number[];
  display_name: string;
  lat: number;
  lon: number;
}

export interface VSImage {
  result_image_url: string;
  render_id: string;
}

export interface GenerateVSImageRes {
  status: boolean;
  result: VSImage;
}

export interface VSResults {
  status: boolean;
  result: {
    render_id: string;
    status: string;
    created_at: number;
    outputs: string[];
    progress: number;
    outputs_styles: string[];
    outputs_room_types: string[];
  };
}

export interface VSOptions {
  styles: string[];
  room_types: string[];
}
