import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdToPoints',
})
export class UsdToPoints implements PipeTransform {
  transform(value: string | null): number {
    if (!value) {
      return 0;
    }
    const usd = Number(value);
    // TODO save thresholds to config
    if (usd < 850) {
      return usd / 12.5;
    } else if (usd >= 850 && usd < 1500) {
      return usd / 10.5;
    } else if (usd >= 1500) {
      return usd / 9;
    } else {
      return usd / 12.5;
    }
  }
}
