import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-text-edit',
  templateUrl: './custom-text-edit.component.html',
  styleUrls: ['./custom-text-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTextEditComponent),
      multi: true,
    },
  ],
})
export class CustomTextEditComponent
  implements ControlValueAccessor, AfterViewInit
{
  private $value!: string;
  @ViewChild('textInput', { static: false }) textInput!: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.textInput.nativeElement.addEventListener(
      'input',
      (event: any) => {
        // console.log(event?.target?.innerText);
        this.valueChange.emit(event?.target?.innerText);
        this.onChange(event?.target?.innerText);
      },
      false,
    );
  }

  get value(): string {
    return this.$value;
  }

  @Input()
  set value(val: string) {
    this.$value = val;
    this.onChange(this.$value);
  }

  @Input() width!: string;

  @Output()
  valueChange = new EventEmitter<any>();

  onChange(_: any): any {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
