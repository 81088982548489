import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CoreSelectors,
  UpdateUserProfile,
  UserProfile,
} from '../../../core/store';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent {
  @ViewSelectSnapshot(CoreSelectors.userProfile)
  userProfile!: UserProfile;
  menuItems = [
    { language: 'en', title: 'EN', isActive: true },
    { language: 'de', title: 'DE', isActive: false },
  ];

  constructor(
    private translate: TranslateService,
    private store: Store,
  ) {}

  onSelectTab(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  get getLanguage() {
    return this.translate.currentLang;
  }
}
