import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalsToStr',
})
export class DecimalsToStrPipe implements PipeTransform {
  transform(val: any): string {
    if (val !== undefined && val !== null) {
      return val.replace(/[$,]/g, '');
    } else {
      return '';
    }
  }
}
