import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSymbol',
})
export class RemoveSymbolPipe implements PipeTransform {
  transform(value: any): any {
    if (value[0] === '#') {
      return value.slice(1);
    }
  }
}
