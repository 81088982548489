<div class="mobile-sidebar">
  <button
    mat-icon-button
    (click)="$event.stopPropagation()"
    class="mobile-sidebar__menu mobile-sidebar__document-menu"
    [matMenuTriggerFor]="menu"
    aria-label="Page menu"
  >
    <mat-icon svgIcon="menu-icon"></mat-icon>
  </button>
  <mat-menu #menu="matMenu" yPosition="below">
    <button
      mat-menu-item
      *ngFor="let menuItem of menuItems; let i = index"
      (click)="onSelectTab(i)"
      [disableRipple]="true"
    >
      <span class="title-expose title-expose--size-14" translate>
        {{ menuItem.title }}
      </span>
    </button>
    <div mat-menu-item
         [matMenuTriggerFor]="languages">
      <div
        class="title-expose title-expose--size-14"
      >{{ "base.text=language" | translate }}</div>
    </div>
  </mat-menu>
</div>

<div class="wrapper-settings" style="border: none">
  <main class="container-settings container-settings--document-settings">
    <section class="section-settings">
      <article class="section-expose__card-settings card-settings">
        <div class="card-settings__header card-settings__header-small" (click)="onReturn()">
          <div class="card-settings__header__icon">
            <mat-icon svgIcon="back-icon" style="margin-right: 8px"></mat-icon>
          </div>
          <p
            class="settings-page__title title-expose--size-14 title-expose"
            translate
          >
            Zurück
          </p>
        </div>
        <div class="card-settings__header card-settings__header-small" (click)="onBackToExposes()">
          <div class="card-settings__header__icon">
            <mat-icon svgIcon="back-icon" style="margin-right: 8px"></mat-icon>
          </div>
          <p
            class="settings-page__title title-expose--size-14 title-expose"
          >
            Exposés
          </p>
        </div>
        <div class="card-settings__content">
          <div class="settings-page__menu">
            <div class="title-expose" translate>Alle Seiten</div>
          </div>
          <div
            class="settings-page__menu"
            (click)="onSelectTab(i)"
            *ngFor="let menuItem of menuItems; let i = index"
          >
            <div
              class="settings-page__title--gray title-expose title-expose--size-14 title-silver"
              [ngClass]="{ active: menuItem.isActive }"
              translate
            >
              {{ menuItem.title }}
            </div>
            <div></div>
          <mat-icon *ngIf="menuItem.isActive" svgIcon="check-icon"></mat-icon>
          </div>
          <div class="setting-page__menu"
               style="margin-top: 40px;"
               mat-menu-item
               [matMenuTriggerFor]="languages">
            <div
              class=" title-expose title-expose--size-14 text-expose--blackRich"
            >{{ "base.text=language" | translate }}</div>
          </div>
        </div>
      </article>
    </section>
  </main>
</div>
<mat-menu #languages="matMenu">
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'en'"
    (click)="changeLanguage('en')"
  >
    EN
  </button>
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'de'"
    (click)="changeLanguage('de')"
  >
    DE
  </button>
</mat-menu>

