import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CreateCustomerResponse } from '../../features/mvp/store';
import {
  ConfirmEmailRequest,
  CoreStateModel,
  CreateUserResponse,
  GetPersonalDataResponse,
  LoginUserResponse,
  PersonDataResponse,
  RegisterEmailResponse,
  ResetPasswordRequest,
  UpdatePersonalDataRequest,
  UserLoginData,
  UserProfile,
  UserProfileResponse,
  UserRegistrationData,
  VerifyEmailResponse,
} from '../store';
import { Store } from '@ngxs/store';
import { BaseResponse } from 'src/app/shared/models/shared.model';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  /* ------------------------------- Auth guard ------------------------------- */

  canActivate(): boolean {
    const userToken = this.store.selectSnapshot<string>(
      (state: { core: CoreStateModel }) => {
        return state.core?.userToken;
      },
    );
    return userToken ? true : false;
  }

  /* -------------------------------- User Auth ------------------------------- */

  createUser(data: UserRegistrationData): Observable<CreateUserResponse> {
    return this.http.post<CreateUserResponse>(
      `${environment.apiUrl}/merchant/create/${environment.botId}`,
      data,
    );
  }

  loginUser(data: UserLoginData): Observable<LoginUserResponse> {
    return this.http.post<LoginUserResponse>(
      `${environment.apiUrl}/merchant/login/${environment.botId}`,
      data,
    );
  }

  setPersonEmail(token: string, email: string): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}/person/email`,
      { email },
      {
        headers: {
          'X-Access-Token': token,
        },
      },
    );
  }

  getPersonData(token: string): Observable<PersonDataResponse> {
    return this.http.get<PersonDataResponse>(
      `${environment.apiUrl}/person/me`,
      {
        headers: {
          'X-Access-Token': token,
        },
      },
    );
  }

  /* ---------------------------------- User ---------------------------------- */

  getUserProfile(): Observable<UserProfileResponse> {
    return this.http.get<UserProfileResponse>(
      `${environment.apiUrl}/api/merchant`,
    );
  }

  updateUserProfile(profile: UserProfile): Observable<UserProfileResponse> {
    return this.http.put<UserProfileResponse>(
      `${environment.apiUrl}/api/merchant`,
      { profile },
    );
  }

  /* ------------------------------ Dashboard TMP ----------------------------- */

  getUserAds(merchant_id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/products`, {
      params: {
        merchant: merchant_id,
        limit: '300',
        offset: '0',
        staging_status: !true,
        category: 'LQDGRCCH91',
      },
    });
  }

  /* ----------------------------------- ++ ----------------------------------- */

  getBotCategories(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/categories/' + id);
  }

  createCustomer(
    login: string,
    password: string,
    name: string,
  ): Observable<CreateCustomerResponse> {
    return this.http.post<CreateCustomerResponse>(
      `${environment.apiUrl}/customer/create/${environment.botId}`,
      {
        login: login,
        password: password,
        profile: {
          name,
        },
      },
    );
  }

  registerEmail(email: string): Observable<RegisterEmailResponse> {
    const token = JSON.parse(localStorage.getItem('core') as string).userToken;

    return this.http.post<RegisterEmailResponse>(
      `${environment.apiUrl}/person/email`,
      { email },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  verifyEmail(hash: string): Observable<VerifyEmailResponse> {
    const apiUrl = environment.apiUrl;
    return this.http.post<VerifyEmailResponse>(
      `${apiUrl}/person/email/verify`,
      { hash },
    );
  }

  getPersonalData(): Observable<GetPersonalDataResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.get<GetPersonalDataResponse>(
      `${environment.apiUrl}/api/merchant`,
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  updatePersonalData(
    company: string,
    address: string,
    telephone: string,
    mobile: string,
  ): Observable<UpdatePersonalDataRequest> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.put<UpdatePersonalDataRequest>(
      `${environment.apiUrl}/api/merchant`,
      {
        profile: {
          company: company,
          address: address,
          telephone: telephone,
          mobile: mobile,
        },
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  updateVSRetries(profile: UserProfile): Observable<UpdatePersonalDataRequest> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.put<UpdatePersonalDataRequest>(
      `${environment.apiUrl}/api/merchant`,
      {
        profile: profile,
      },
    );
  }
  generateAIForBRNProduct(
    productId: string,
    language: string,
  ): Observable<any> {
    return this.http.post(`${environment.techfunderUrl}/realty/texts`, {
      product_id: productId,
      project: 'realty_ai',
      answer_language: language,
      line_feed: '<br>',
      bot_id: environment.botId,
      num_words_location: 30,
      num_words_description: 80,
    });
  }

  login(login: string, password: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/customer/login/${environment.botId}`,
      { login, password },
    );
  }

  getProducts(id: string): Observable<any> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/products/${environment.botId}`,
      {
        params: {
          customer_id: `(?=(.|\n)*?(${id}))(?=(.|\n)*?(${id}))(.|\\n)*`,
          limit: '300',
          offset: '0',
        },
      },
    );
  }

  getStagingProducts(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/products`, {
      params: {
        merchant: id,
        limit: '300',
        offset: '0',
        staging_status: true,
      },
    });
  }
  getTrashbinImages(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/products`, {
      params: {
        merchant: id,
        limit: '300',
        offset: '0',
        category: 'folder',
        trashbin_status: true,
      },
    });
  }

  getFolderProducts(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/products`, {
      params: {
        merchant: id,
        limit: '300',
        offset: '0',
        category: 'folder',
        trashbin_status: false,
      },
    });
  }

  getProduct(id: string, botId?: string): Observable<any> {
    const botIdLocal = botId ? botId : environment.botId;
    return this.http.get<any[]>(
      `${environment.apiUrl}/product/${botIdLocal}/${id}`, //dev bot
    );
  }

  confirmEmail(email: string): Observable<any> {
    return this.http.put<ConfirmEmailRequest>(
      `${environment.apiUrl}/api/merchant/reset/password/init`,
      {
        email: email,
      },
      {
        headers: {
          'X-Access-Token': `${environment.botToken}`,
        },
      },
    );
  }

  resetPassword(
    token: string,
    newPassword: string,
    confirmPassword: string,
  ): Observable<any> {
    return this.http.put<ResetPasswordRequest>(
      `${environment.apiUrl}/api/merchant/reset/password/confirm`,
      {
        token: token,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      {
        headers: {
          'X-Access-Token': `${environment.botToken}`,
        },
      },
    );
  }

  paymentIntentRequest(amount: number): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/merchant/balance/prepare/stripe`,
      { amount: amount },
    );
  }
  getTransactionList(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/merchant/billing/transactions`,
    );
  }

  public addStagingProduct(product: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/product`, product, {});
  }

  public deleteFolder(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/product/${id}`);
  }

  public editStagingProduct(product: any, id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/product/${id}`, product);
  }
}
