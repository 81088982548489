import { Component } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { CoreSelectors, ToggleSidebarSize } from 'src/app/core/store';

@Component({
  selector: 'app-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss'],
})
export class NavigationSidebarComponent {
  @ViewSelectSnapshot(CoreSelectors.sidebarShrinked)
  sidebarShrinked!: boolean;

  constructor(private store: Store) {}

  toggleSidebar(): void {
    this.store.dispatch(new ToggleSidebarSize());
  }
}
