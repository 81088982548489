import { Selector } from '@ngxs/store';
import { ExposeState } from './expose.state';
import {
  AddressSearch,
  AIDescriptions,
  DocumentAttributes,
  DocumentInterface,
  ExposeStateModel,
  ImagesPageSteps,
  VSOptions,
} from './expose-state.model';
import { SetCurrentForm } from './expose.actions';
import { StagingState, StagingStateModel } from '../../staging/store';

export class ExposeSelectors {
  @Selector([ExposeState])
  static productTemporaryInfo(state: ExposeStateModel): DocumentAttributes {
    return state.documentTemplate;
  }

  @Selector([ExposeState])
  static imagesPageStep(state: ExposeStateModel): ImagesPageSteps {
    return state.imagesPageStep;
  }

  @Selector([ExposeState])
  static imagesDescriptions(state: ExposeStateModel): AIDescriptions[] {
    return state.imagesDescriptions;
  }

  @Selector([ExposeState])
  static documentPageState(state: ExposeStateModel): boolean {
    return state.documentPageState;
  }

  @Selector([ExposeState])
  static document(state: ExposeStateModel): DocumentInterface {
    return state.document;
  }

  @Selector([ExposeState])
  static documentTemplate(state: ExposeStateModel): DocumentAttributes {
    return state.documentTemplate;
  }

  @Selector([ExposeState])
  static documentCreatingError(state: ExposeStateModel): string {
    return state.documentCreatingError;
  }

  @Selector([ExposeState])
  static currentForm(state: ExposeStateModel): any {
    return state.currentForm;
  }

  @Selector([ExposeState])
  static aiSnapshotList(state: ExposeStateModel): any {
    return state.aiSnapshotList;
  }

  @Selector([ExposeState])
  static geolocation(state: ExposeStateModel): any {
    return state.geolocation;
  }
  @Selector([ExposeState])
  static vsGeneratedImages(state: ExposeStateModel): any[] {
    return state.vsGeneratedImages;
  }
  @Selector([ExposeState])
  static vsSelectedImage(state: ExposeStateModel): string {
    return state.vsSelectedImage;
  }

  @Selector([ExposeState])
  static vsRenderId(state: ExposeStateModel): string {
    return state.vsRenderId;
  }
  @Selector([ExposeState])
  static vsOptions(state: ExposeStateModel): VSOptions {
    return state.vsOptions;
  }

  @Selector([ExposeState])
  static geolocationAll(state: ExposeStateModel): AddressSearch[] {
    return state.geolocationAll;
  }

  @Selector([ExposeState])
  static vsImagesStyles(state: ExposeStateModel): string[] {
    return state.vsImagesStyles;
  }
  @Selector([ExposeState])
  static vsImagesRoomTypes(state: ExposeStateModel): string[] {
    return state.vsImagesRoomTypes;
  }
  @Selector([ExposeState])
  static uploadedImages(state: ExposeStateModel): string[] {
    return state.uploadedImages;
  }
}
