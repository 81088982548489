<header class="header" [style]="newStyles">
  <!--  <form class="header__searchbar" *ngIf="!searchNotActive">-->
  <!--    <i class="searchbar__icon">-->
  <!--      <mat-icon [svgIcon]="'search-icon'"></mat-icon>-->
  <!--    </i>-->
  <!--    <input-->
  <!--      type="text"-->
  <!--      class="searchbar__input"-->
  <!--      [placeholder]="'Immobilien, Kontakte, Mails oder Termine finden' | translate"-->
  <!--    />-->
  <!--  </form>-->

  <div class="header__controls">
    <!--    <span class="notification" *ngIf="!searchNotActive">-->
    <!--&lt;!&ndash;      <b class="notification__count">  </b>&ndash;&gt;-->
    <!--      <i class="notification__icon">-->
    <!--        <mat-icon [svgIcon]="'notification-icon'"></mat-icon>-->
    <!--      </i>-->
    <!--    </span>-->
    <span
      class="profile profile__balance"
      *ngIf="!searchNotActive || balanceStatus"
      [matMenuTriggerFor]="balanceMenu"
    >
      <b>{{ userPlan.balance }}</b>
    </span>
    <span class="profile" [matMenuTriggerFor]="profileMenu">
      <b>{{ userNameShort }}</b>
    </span>
  </div>
</header>

<mat-menu #profileMenu="matMenu" yPosition="below" class="mat-menu-wide">
  <div class="profile-modal">
    <div class="profile-modal__user">
      <div class="user__avatar">
        <img
          *ngIf="userProfile.image"
          src="{{ userProfile.image }}"
          [alt]="userNameShort"
        />
        <span *ngIf="!userProfile.image">
          <b>{{ userNameShort }}</b>
        </span>
      </div>
      <div class="user__info">
        <h3>{{ userProfile.name }}</h3>
        <p>{{ userProfile.company }}</p>
      </div>
    </div>
    <ul class="profile-modal__menu">
      <li [routerLink]="'/onboarding/personal-data'">
        {{ "profile.modal.manage" | translate }}
      </li>
      <li (click)="logOut()">{{ "profile.modal.logout" | translate }}</li>
      <!-- Tmp before profile page -->
      <li mat-menu-item [matMenuTriggerFor]="languages">
        {{ "base.text=language" | translate }}
      </li>
    </ul>
  </div>
</mat-menu>

<mat-menu #languages="matMenu">
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'en'"
    (click)="changeLanguage('en')"
  >
    EN
  </button>
  <button
    mat-menu-item
    [disabled]="currentLanguage === 'de'"
    (click)="changeLanguage('de')"
  >
    DE
  </button>
</mat-menu>
<mat-menu #balanceMenu="matMenu">
  <div class="profile-modal">
    <div class="profile-modal__user">
      <div class="user__avatar user__avatar--grey">
        <span *ngIf="!userProfile.image">
          <b>{{ userPlan.balance }}</b>
        </span>
      </div>
      <div class="user__info">
        <h3 translate>Credits verfügbar</h3>
        <div class="user__links">
          <a routerLink="/payments/checkout" translate>Kaufe mehr Credits</a>
          <a routerLink="/payments" translate>Transaktionshistorie</a>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
