import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  ConfirmEmailResponse,
  CoreStateModel,
  FolderInterface,
  LoginUserResponse,
  PaymentIntent,
  ResetPasswordResponse,
  Transaction,
  UploadImageResponse,
  UserPlan,
  UserProfile,
  UserProfileResponse,
} from './core-state.model';
import { environment } from '../../../environments/environment';
import {
  catchError,
  combineLatest,
  mergeMap,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import {
  ClearErrorEmail,
  ConfirmEmail,
  CreateCustomer,
  CreateUser,
  GenerateAIForProduct,
  GetBotCategories,
  GetPersonalData,
  GetProducts,
  LoginCustomer,
  ResetPassword,
  LoginUser,
  SetCustomer,
  UpdatePersonalData,
  VerifyEmail,
  LogOut,
  GetUserProfile,
  ToggleSidebarSize,
  GetUserAds,
  LoginUserAfterEmailVerified,
  ResendEmail,
  ClearCoreState,
  UpdateVSRetries,
  UpdateUserProfile,
  CreatePaymentIntent,
  GetTransactionList,
  GetStagingProduct,
  CreateStagingProduct,
  EditStagingProduct,
  CreateFolderProduct,
  SelectFolder,
  GetFolderProducts,
  EditFolder,
  GetTrashbinImages,
  DeleteFolder,
  MoveImgsToFolder,
  PaymentModalStatus,
  DeleteExposeProduct,
  UploadImage,
  RegisterEmail,
} from './core.actions';
import { CoreService } from '../services/core.service';
import {
  CreateCustomerResponse,
  GetProduct,
  MvpStateModel,
} from '../../features/mvp/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/shared/models/shared.model';
import { NotificationService } from '../services/notification.service';
import {
  ClearExposeState,
  DocumentAttributesVoid,
  DocumentInterface,
  ExposeState,
} from '../../features/expose/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, get } from 'lodash';
import { Config } from '../services/requests.config';
import { UploadService } from '../services/upload.service';

@State<CoreStateModel>({
  name: 'core',
})
@Injectable()
export class CoreState implements NgxsOnInit {
  constructor(
    private coreService: CoreService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private router: Router,
    private notification: NotificationService,
    private translate: TranslateService,
    private uploadService: UploadService,
    private store: Store,
  ) {}

  /* ---------------------------------- Init ---------------------------------- */

  ngxsOnInit({ getState, dispatch }: StateContext<CoreStateModel>) {
    const auth = getState().userToken;
    if (auth) {
      dispatch([new GetUserProfile()]);
    }
  }

  /* ---------------------------------- Auth ---------------------------------- */

  @Action(CreateUser)
  createUser(
    { patchState }: StateContext<CoreStateModel>,
    { data }: CreateUser,
  ): Observable<BaseResponse> {
    return this.coreService.createUser(data).pipe(
      mergeMap((createUserRes) => {
        return combineLatest([
          of(createUserRes),
          this.coreService.setPersonEmail(createUserRes.token, data.login),
        ]);
      }),
      tap(([createUserRes, setEmailRes]) => {
        if (setEmailRes.status) {
          patchState({
            verificationToken: createUserRes.token,
            verificationEmail: data.login,
          });
          this.redirectTo(['/onboarding', 'registration', 'confirm-email']);
        }
      }),
      catchError((err) => {
        this.notification.error(this.translate.instant(err.error.error));
        return of(err);
      }),
    );
  }

  @Action(LoginUser)
  loginUser(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { data }: LoginUser,
  ): Observable<LoginUserResponse> {
    return this.coreService.loginUser(data).pipe(
      mergeMap((loginRes) => {
        return combineLatest([
          of(loginRes),
          this.coreService.getPersonData(loginRes.token),
        ]);
      }),
      tap(([loginRes, personRes]) => {
        if (personRes.status && personRes.person.profile.email === data.login) {
          patchState({
            userId: loginRes.merchant_id,
            userToken: loginRes.token,
          });
        } else {
          dispatch(new ResendEmail(data, loginRes.token));
          throw new Error('Account not yet verified!');
        }
      }),
      mergeMap(() => {
        return this.coreService.getUserProfile();
      }),
      tap((data) => {
        patchState({
          userProfile: data.profile,
          userPlan: data.plan,
        });
        this.redirectTo(['/staging']);
      }),
      catchError((err) => {
        this.notification.error(this.translate.instant(err.error.error));
        patchState({
          userId: '',
          userToken: '',
        });
        return of(err);
      }),
    );
  }

  @Action(LoginUserAfterEmailVerified)
  loginUserAfterEmailVerification({
    patchState,
  }: StateContext<CoreStateModel>): Observable<LoginUserResponse> {
    patchState({
      verificationEmail: undefined,
      verificationToken: undefined,
    });
    return this.coreService.getUserProfile().pipe(
      tap((data) => {
        patchState({
          userProfile: data.profile,
          userPlan: data.plan,
        });
        this.redirectTo(['/staging']);
      }),
      catchError((err) => {
        this.notification.error(
          this.translate.instant('Wrong email address or wrong password'),
        );
        patchState({
          userId: '',
          userToken: '',
        });
        return of(err);
      }),
    );
  }

  @Action(VerifyEmail)
  verifyEmail(
    { patchState, getState }: StateContext<CoreStateModel>,
    { hash }: VerifyEmail,
  ): Observable<any> {
    return this.coreService.verifyEmail(hash).pipe(
      tap((res) => {
        if (res.status) {
          // patchState({
          //   userToken: getState().verificationToken || undefined,
          // });
          this.redirectTo(['/onboarding/login']);
        } else {
          patchState({
            verificationEmail: undefined,
            verificationToken: undefined,
          });
          this.redirectTo(['/onboarding']);
        }
      }),
      catchError((err) => {
        patchState({
          verificationEmail: undefined,
          verificationToken: undefined,
        });
        this.notification.error(this.translate.instant(err.error.error));
        this.redirectTo(['/onboarding']);
        throw err;
      }),
    );
  }

  @Action(ResendEmail)
  resendEmail(
    { patchState }: StateContext<CoreStateModel>,
    { data, token }: ResendEmail,
  ): Observable<any> {
    return this.coreService.setPersonEmail(token, data.login).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            verificationToken: token,
            verificationEmail: data.login,
          });
          this.redirectTo(['/onboarding', 'registration', 'confirm-email']);
        }
      }),
      catchError((err) => {
        this.notification.error(err.error.error);
        return of(err);
      }),
    );
  }

  @Action(LogOut)
  logout({ patchState, dispatch }: StateContext<CoreStateModel>): void {
    patchState({
      userToken: undefined,
      userId: undefined,
      customerToken: undefined,
      customerId: undefined,
      userProfile: {
        name: '',
        image: '',
        email: undefined as any,
        vs_retries: undefined as any,
        company: undefined as any,
        address: undefined as any,
        mobile: undefined as any,
        telephone: undefined as any,
        language: undefined as any,
        company_info: undefined as any,
        expose_logotypes: [
          {
            default: undefined as any,
            logo: undefined as any,
          },
        ],
        colors: {},
        fonts: {},
      },
      userPlan: {
        balance: undefined as any,
        expires: undefined as any,
        mode: undefined as any,
        status: undefined as any,
      },
      verificationEmail: undefined,
      verificationToken: undefined,
      aiProductDescriptions: undefined,
      paymentIntent: undefined,
      products: [],
      product: undefined,
      stagingProduct: undefined,
      trashbinFolder: undefined as any,
      selectedFolder: undefined as any,
      transactions: [],
    });
    dispatch(new ClearExposeState());
    // localStorage.removeItem('productId');
    sessionStorage.clear();
    localStorage.clear();
    this.redirectTo(['/onboarding']);
  }

  /* ---------------------------------- User ---------------------------------- */

  @Action(GetUserProfile)
  getUserProfile({
    patchState,
  }: StateContext<CoreStateModel>): Observable<UserProfileResponse> {
    return this.coreService.getUserProfile().pipe(
      tap((res) => {
        patchState({
          userProfile: res.profile,
          userPlan: res.plan,
        });
        this.translate.use(res.profile.language);
      }),
      catchError((err) => {
        this.notification.error(this.translate.instant(err.error.error));
        patchState({
          userId: '',
          userToken: '',
        });
        return of(err);
      }),
    );
  }

  @Action(UpdateUserProfile)
  updateUserProfile(
    { patchState }: StateContext<CoreStateModel>,
    { profile }: UpdateUserProfile,
  ): Observable<UserProfileResponse> {
    return this.coreService.updateUserProfile(profile).pipe(
      tap((res) => {
        patchState({
          userProfile: res.profile,
        });
      }),
      catchError((err) => {
        this.notification.error(err.error.error);
        patchState({
          userId: '',
          userToken: '',
        });
        return of(err);
      }),
    );
  }

  /* --------------------------------- Layout --------------------------------- */

  @Action(ToggleSidebarSize)
  toggleSidebarSize({
    patchState,
    getState,
  }: StateContext<CoreStateModel>): void {
    patchState({
      sidebarShrinked: !getState().sidebarShrinked,
    });
  }

  /* ------------------------------ Dashboard TMP ----------------------------- */

  @Action(GetUserAds)
  getUserAds({
    patchState,
    getState,
  }: StateContext<CoreStateModel>): Observable<any> {
    return this.coreService.getUserAds(getState().userId).pipe(
      tap((res) => {
        const products = res.products.filter(
          (product: any) => product.category_id === Config.defaultCategory,
        );
        patchState({
          totalAds: products.length,
          products: products,
        });
      }),
      catchError((err) => {
        this.notification.error(err.error.error);

        return of(err);
      }),
    );
  }

  /* ----------------------------------- ++ ----------------------------------- */

  @Action(GetBotCategories)
  getBotCategories(
    { getState, patchState }: StateContext<CoreStateModel>,
    {}: GetBotCategories,
  ): any {
    return this.coreService.getBotCategories(environment.botId).pipe(
      tap((result) => {
        if (result.status) {
          const state = getState();
          patchState({
            ...state,
            categories: result.categories,
          });
        }
      }),
      catchError((err) => {
        patchState({
          categories: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(CreateCustomer)
  createCustomer(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { login, password, name }: CreateCustomer,
  ): Observable<CreateCustomerResponse> {
    return this.coreService.createCustomer(login, password, name).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            customerId: res.customer_id,
            customerToken: res.token,
          });

          localStorage.setItem('walletAuthToken', res.token);
        }
      }),
      catchError((err) => {
        this.notification.error(err.error.error);
        patchState({
          customerId: '',
          customerToken: '',
        });

        return throwError(err);
      }),
    );
  }

  @Action(GetPersonalData)
  getPersonalData(
    { patchState }: StateContext<CoreStateModel>,
    {}: GetPersonalData,
  ): Observable<any> {
    patchState({
      company: undefined,
      address: undefined,
      telephone: undefined,
      mobile: undefined,
    });
    return this.coreService.getPersonalData().pipe(
      tap((res) => {
        if (res.status && res.profile) {
          patchState({
            company: res.profile?.company,
            address: res.profile?.address,
            telephone: res.profile?.telephone,
            mobile: res.profile?.mobile,
          });
        }
      }),
    );
  }

  @Action(UpdatePersonalData)
  updatePersonalData(
    {}: StateContext<CoreStateModel>,
    { company, address, telephone, mobile }: UpdatePersonalData,
  ): Observable<any> {
    return this.coreService
      .updatePersonalData(company, address, telephone, mobile)
      .pipe();
  }

  @Action(UpdateVSRetries)
  updateVSRetries(
    { patchState }: StateContext<CoreStateModel>,
    { profile }: UpdateVSRetries,
  ): Observable<any> {
    return this.coreService.updateVSRetries(profile).pipe(
      tap((res) => {
        patchState({
          userProfile: profile,
        });
      }),
    );
  }

  @Action(GenerateAIForProduct)
  generateAIForProduct(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { productId, language }: GenerateAIForProduct,
  ): Observable<any> {
    patchState({
      aiProductDescriptions: undefined,
    });
    return this.coreService.generateAIForBRNProduct(productId, language).pipe(
      tap((res) => {
        if (res.answer) {
          patchState({
            aiProductDescriptions: res.answer,
          });
        }
      }),
    );
  }

  @Action(SetCustomer)
  setCustomer(
    { patchState }: StateContext<CoreStateModel>,
    { customerId, customerToken }: SetCustomer,
  ): any {
    patchState({
      customerId: customerId,
      customerToken: customerToken,
    });
  }

  @Action(LoginCustomer)
  loginCustomer(
    { patchState }: StateContext<CoreStateModel>,
    { login, password }: LoginCustomer,
  ): Observable<any> {
    return this.coreService.login(login, password).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            customerToken: res.token,
            customerId: res.customer_id,
          });
          localStorage.setItem('customerToken', res.token);
          localStorage.setItem('customerId', res.customer_id);
        }
      }),
      catchError((err) => {
        this.notification.error('No account found');
        throw err;
      }),
    );
  }

  @Action(GetProducts)
  getProducts(
    { patchState }: StateContext<CoreStateModel>,
    { customerId }: GetProducts,
  ): Observable<any> {
    return this.coreService.getProducts(customerId).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            products: res.products,
          });
        }
      }),
      catchError((err) => {
        patchState({
          products: [],
        });
        throw err;
      }),
    );
  }

  @Action(GetStagingProduct)
  getStagingProduct(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { merchantId }: GetStagingProduct,
  ): Observable<any> {
    return this.coreService.getStagingProducts(merchantId).pipe(
      tap((res) => {
        if (res.status) {
          // console.log(res.products[0]);

          patchState({
            stagingProduct: res.products[0],
          });
        }
      }),
      catchError((err) => {
        throw err;
      }),
    );
  }
  @Action(GetTrashbinImages)
  getTrashbinImages(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { merchantId }: GetTrashbinImages,
  ): Observable<any> {
    return this.coreService.getTrashbinImages(merchantId).pipe(
      tap((res) => {
        if (res.status) {
          // console.log(res.products[0]);
          patchState({
            trashbinFolder: res.products[0],
          });
        }
      }),
      catchError((err) => {
        throw err;
      }),
    );
  }
  @Action(GetFolderProducts)
  getFolderProducts(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { merchantId }: GetFolderProducts,
  ): Observable<any> {
    return this.coreService.getFolderProducts(merchantId).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            folders: res.products,
          });
        }
      }),
      catchError((err) => {
        throw err;
      }),
    );
  }

  @Action(EditStagingProduct)
  editDocumentProduct(
    { patchState, dispatch }: StateContext<CoreStateModel>,
    { params, id, notSaveInVariable }: EditStagingProduct,
  ): Observable<any> {
    return this.coreService.editStagingProduct(params, id).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            stagingProduct: { ...params, product_id: id },
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(EditFolder)
  editFolder(
    { patchState, getState }: StateContext<CoreStateModel>,
    { params, id }: EditFolder,
  ): Observable<any> {
    const selectedId = getState().selectedFolder;
    const folders = JSON.parse(JSON.stringify(getState().folders));
    return this.coreService.editStagingProduct(params, id).pipe(
      tap((res) => {
        if (res.status) {
          if (params.attributes.trashbin_status) {
            patchState({
              trashbinFolder: { ...params, product_id: id },
            });
          } else {
            const foldersArray = folders.map((folder: any) => {
              if (folder.product_id === id) {
                return { ...params, product_id: id };
              } else {
                return folder;
              }
            });
            patchState({
              folders: foldersArray,
            });
          }
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(CreateStagingProduct)
  createDocumentProduct(
    { patchState, getState }: StateContext<CoreStateModel>,
    { product }: CreateStagingProduct,
  ): any {
    return this.coreService.addStagingProduct(product).pipe(
      tap((res) => {
        if (res.status) {
          const document = { ...product, product_id: res.product_id };
          patchState({
            stagingProduct: document,
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(CreateFolderProduct)
  createFolderProduct(
    { patchState, getState }: StateContext<CoreStateModel>,
    { product }: CreateFolderProduct,
  ): any {
    const folders = getState().folders;
    return this.coreService.addStagingProduct(product).pipe(
      tap((res) => {
        if (res.status) {
          const document = { ...product, product_id: res.product_id };
          if (product.attributes.trashbin_status) {
            patchState({
              trashbinFolder: document,
            });
          } else {
            patchState({
              selectedFolder: document.product_id,
              folders: [...folders, document],
            });
          }
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }
  @Action(DeleteFolder)
  deleteFolder(
    { patchState, getState }: StateContext<CoreStateModel>,
    { id }: DeleteFolder,
  ): any {
    let folders = cloneDeep(getState().folders);
    folders = folders.filter((folder) => folder.product_id !== id);
    return this.coreService.deleteFolder(id).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            selectedFolder: folders.length
              ? folders[0].product_id
              : 'all_products',
            folders: folders,
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(SelectFolder)
  selectFolder(
    { patchState }: StateContext<CoreStateModel>,
    { productId }: SelectFolder,
  ): any {
    patchState({
      selectedFolder: productId,
    });
  }

  @Action(GetProduct)
  getProduct(
    { getState, setState, patchState }: StateContext<MvpStateModel>,
    { id, botId }: GetProduct,
  ) {
    return this.coreService.getProduct(id, botId).pipe(
      tap((result) => {
        if (result.status) {
          patchState({
            product: result,
          });
        }
      }),
      catchError((err) => {
        patchState({
          product: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(ConfirmEmail)
  confirmEmail(
    { patchState }: StateContext<ConfirmEmailResponse>,
    { email }: ConfirmEmail,
  ): Observable<any> {
    patchState({ status: undefined, error: undefined });

    return this.coreService.confirmEmail(email).pipe(
      tap((res) => {
        if (res.status) {
          this.notification.info(
            this.translate.instant(
              'Wir haben Ihnen eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passworts gesendet. Bitte überprüfen Sie Ihre E-Mails.',
            ),
          );
          patchState({
            status: res.status,
          });
        }
      }),
      catchError((err) => {
        patchState({
          error: this.translate.instant(err.error.error),
        });
        throw err;
      }),
    );
  }

  @Action(ClearErrorEmail)
  clearErrorEmail(
    { patchState }: StateContext<ConfirmEmailResponse>,
    {}: ClearErrorEmail,
  ): any {
    patchState({
      error: undefined,
    });
  }

  @Action(ClearCoreState)
  clearCoreState(
    { patchState }: StateContext<CoreStateModel>,
    {}: ClearCoreState,
  ): any {
    patchState({
      aiProductDescriptions: undefined,
      paymentIntent: undefined,
      products: [],
      product: undefined,
      stagingProduct: undefined,
      transactions: [],
    });
  }

  @Action(ResetPassword)
  resetPassword(
    { patchState }: StateContext<ResetPasswordResponse>,
    { token, newPassword, confirmPassword }: ResetPassword,
  ): Observable<any> {
    return this.coreService
      .resetPassword(token, newPassword, confirmPassword)
      .pipe(
        tap((res) => {
          if (res.status) {
            this.notification.info(
              this.translate.instant('Passwort erfolgreich geändert'),
            );

            setTimeout(() => {
              this.redirectTo(['/onboarding/login']);
            }, 2000);

            patchState({
              status: res.status,
            });
          }
        }),
        catchError((err) => {
          this.notification.error(this.translate.instant(err.error.error));

          return throwError(err);
        }),
      );
  }

  @Action(CreatePaymentIntent)
  createPaymentIntent(
    { patchState }: StateContext<CoreStateModel>,
    { amount }: CreatePaymentIntent,
  ): Observable<any> {
    return this.coreService.paymentIntentRequest(amount).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            paymentIntent: res,
          });
        }
      }),
      catchError((err) => {
        this.notification.error(err.error.error);
        return throwError(err);
      }),
    );
  }

  @Action(GetTransactionList)
  getTransactionList(
    { patchState }: StateContext<CoreStateModel>,
    {}: GetTransactionList,
  ): Observable<any> {
    return this.coreService.getTransactionList().pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            transactions: res.transactions,
          });
        }
      }),
      catchError((err) => {
        this.notification.error(err.error.error);
        return throwError(err);
      }),
    );
  }

  @Action(PaymentModalStatus)
  paymentModalStatus(
    { patchState }: StateContext<CoreStateModel>,
    { status }: PaymentModalStatus,
  ): any {
    patchState({
      paymentModalStatus: status,
    });
  }

  // @Action(MoveImgsToFolder)
  // moveImgsToFolder(
  //     { patchState }: StateContext<CoreStateModel>,
  //     { productFrom, productTo, idTo, idFrom }: MoveImgsToFolder,
  //   ): Observable<BaseResponse> {
  //     return this.coreService.editStagingProduct(productTo, idTo).pipe(
  //       mergeMap((productToEdited) => {
  //         return combineLatest([
  //           of(productToEdited),
  //           this.coreService.editStagingProduct(productFrom, idFrom),
  //         ]);//       }),
  //       tap(([productToEdited, productFromEdited]) => {
  //         if (productToEdited.status) {
  //           if (paramsTo.attributes.trashbin_status) {
  //             patchState({
  //               trashbinFolder: { ...paramsTo, product_id: idTo },
  //             });
  //           } else {
  //             const foldersArray = folders.map((folder: any) => {
  //               if (folder.product_id === id) {
  //                 return { ...params, product_id: id };
  //               } else {
  //                 return folder;
  //               }
  //             });
  //             patchState({
  //               folders: foldersArray,
  //             });
  //           }
  //         }
  //       }),
  //       catchError((err) => {
  //         this.notification.error(this.translate.instant(err.error.error));
  //         return of(err);
  //       }),
  //     );
  //   })}

  private redirectTo(path: string[], state?: any): void {
    this.zone.run(() =>
      this.router.navigate(path, {
        state,
      }),
    );
  }

  @Action(DeleteExposeProduct)
  deleteExposeProduct(
    { patchState, getState }: StateContext<CoreStateModel>,
    { id }: DeleteExposeProduct,
  ): any {
    let products = cloneDeep(getState().products);
    products = products.filter((folder) => folder.product_id !== id);
    return this.coreService.deleteFolder(id).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            products,
            totalAds: products.length,
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(UploadImage)
  uploadImage(
    { patchState }: StateContext<UploadImageResponse>,
    { file }: UploadImage,
  ): Observable<UploadImageResponse> {
    patchState({ url: undefined });
    return this.uploadService.uploadImage(file).pipe(
      tap((res) => {
        if (res) {
          patchState({
            url: res.url,
          });
        }
      }),
    );
  }

  @Action(RegisterEmail)
  registerEmail(
    {}: StateContext<CoreStateModel>,
    { email }: RegisterEmail,
  ): Observable<any> {
    return this.coreService.registerEmail(email).pipe();
  }
}
