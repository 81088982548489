import { Selector } from '@ngxs/store';
import { MvpState } from './mvp.state';
import {
  MvpProductAttributes,
  MvpStateModel,
  Product,
  UploadImageResponse,
} from './mvp-state.model';

export class MvpSelectors {
  @Selector([MvpState])
  static productTemporaryInfo(state: MvpStateModel): MvpProductAttributes {
    return state.productTemporaryInfo;
  }

  @Selector([MvpState])
  static imageFiles(state: MvpStateModel): UploadImageResponse[] {
    return state.imageFiles;
  }

  @Selector([MvpState])
  static address(state: MvpStateModel): any {
    return state.address;
  }

  @Selector([MvpState])
  static productId(state: MvpStateModel): string {
    return state.productId;
  }

  @Selector([MvpState])
  static advertisement(state: MvpStateModel): string {
    return state.advertisement;
  }

  @Selector([MvpState])
  static product(state: MvpStateModel): Product {
    return state.product;
  }

  @Selector([MvpState])
  static pathname(state: MvpStateModel): string {
    return state.pathname;
  }
}
