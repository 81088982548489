import { Action, State, StateContext } from '@ngxs/store';
import {
  AISnapshot,
  AISnapshotList,
  AISnapshots,
  aiTextValue,
  DocumentAttributesVoid,
  ExposeStateModel,
  GenerateVSImageRes,
  VSResults,
} from './expose-state.model';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  AddDataAfterProductCreated,
  AddParamsToDocumentTemplate,
  ClearExposeState,
  ClearPageForms,
  ClearUploadedImages,
  ClearVSImages,
  CreateDocumentProduct,
  DocumentPageState,
  EditDocumentProduct,
  GenerateVSImage,
  GetAITextForImages,
  GetDocument,
  GetDocumentChangesById,
  GetGeneratedVSImages,
  GetGeolocationByName,
  RegenerateText,
  ReGenerateVSImage,
  SaveAITextsChanges,
  SaveVSImage,
  SelectVSImage,
  SetCurrentForm,
  SetDocumentProduct,
  SetImagesPageStep,
  UpdateSnapshotList,
  UploadImages,
  ViewVsOptions,
} from './expose.actions';
import { ExposeService } from '../services/expose.service';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Md5 } from 'ts-md5';
import { DEFAULT_GEOLOCATION } from '../../../shared/components/map/map-config';
import { NotificationService } from '../../../core/services/notification.service';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { VSImage } from '../../staging/store';
import { GenerateAIForProduct } from '../../../core/store';
import { Config } from '../../../core/services/requests.config';
import { GetProduct, MvpStateModel } from '../../mvp/store';

@State<ExposeStateModel>({
  name: 'expose',
  defaults: {
    documentTemplate: DocumentAttributesVoid as any,
    imagesPageStep: undefined as any,
    imagesDescriptions: undefined as any,
    documentPageState: undefined as any,
    document: undefined as any,
    documentCreatingError: undefined as any,
    currentForm: undefined as any,
    aiSnapshotList: undefined as any,
    geolocation: undefined as any,
    vsGeneratedImages: [],
    vsImagesStyles: [],
    vsImagesRoomTypes: [],
    vsSelectedImage: undefined as any,
    vsRenderId: undefined as any,
    vsOptions: undefined as any,
    geolocationAll: undefined as any,
    error: false,
    uploadedImages: [],
  },
})
@Injectable()
export class ExposeState {
  constructor(
    private zone: NgZone,
    private router: Router,
    private exposeService: ExposeService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  @Action(AddParamsToDocumentTemplate)
  addParamsToDocumentTemplate(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { settings }: AddParamsToDocumentTemplate,
  ): any {
    const temporaryDocument = getState().documentTemplate;
    patchState({
      documentTemplate: { ...temporaryDocument, ...settings },
    });
  }

  @Action(SetImagesPageStep)
  setImagesPageStep(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { step }: SetImagesPageStep,
  ): any {
    const temporaryDocument = getState().documentTemplate;
    patchState({
      imagesPageStep: step,
    });
  }

  @Action(GetAITextForImages)
  getAITextForImages(
    { patchState, getState, dispatch }: StateContext<ExposeStateModel>,
    { language, productId }: GetAITextForImages,
  ): any {
    const temporaryDocument = getState().documentTemplate;
    patchState({
      documentCreatingError: '',
    });
    const reducedImagesArr = temporaryDocument.expose_images.filter(
      (img, i) =>
        i !== 1 && i !== 2 && i <= Config.imgWithInfoLast && img?.image,
    );
    return this.exposeService
      .getAITextsForImages(reducedImagesArr, temporaryDocument, language)
      .pipe(
        tap((res) => {
          const voidAnswer = {
            answer: '',
            title: '',
            status: true,
            details: [''],
          };
          const imagesInfo = temporaryDocument.expose_images.map((info, i) => {
            if (info.image && i === 0) {
              return res[0];
            } else if (i > 2 && i <= Config.imgWithInfoLast && info?.image) {
              const diff = Config.imgWithInfoLast - res.length;
              return res[i - diff];
            } else {
              return voidAnswer;
            }
          });
          console.log(imagesInfo);
          patchState({
            imagesDescriptions: imagesInfo,
            // aiSnapshotList: this.createAISnapshotLists(textList)
          });
          dispatch(new GenerateAIForProduct(productId, language));
        }),
        catchError((err) => {
          patchState({
            documentCreatingError: err.error.error,
          });
          return throwError(err);
        }),
      );
  }

  @Action(DocumentPageState)
  documentPageState(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { state }: DocumentPageState,
  ): any {
    patchState({
      documentPageState: state,
    });
  }

  @Action(AddDataAfterProductCreated)
  addDataAfterProductCreated(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { id }: AddDataAfterProductCreated,
  ): any {
    const arrays = getState().imagesDescriptions.map(
      (info) => info.details,
    ) as any;
    const imagesDescriptions = [].concat.apply([], arrays);
    return this.exposeService.setDataForImages(imagesDescriptions, id).pipe(
      tap((res) => {
        patchState({});
      }),
    );
  }

  //CreateDocumentProduct
  @Action(CreateDocumentProduct)
  createDocumentProduct(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { product }: CreateDocumentProduct,
  ): any {
    patchState({
      documentCreatingError: '',
    });
    return this.exposeService.addProduct(product).pipe(
      tap((res) => {
        if (res.status) {
          const document = { ...product, product_id: res.product_id };
          localStorage.setItem('productId', res.product_id);
          patchState({
            document: document,
          });
        }
      }),
      catchError((err) => {
        patchState({
          documentCreatingError: err.error.error,
        });
        return throwError(err);
      }),
    );
  }

  @Action(SetCurrentForm)
  setCurrentForm(
    { patchState }: StateContext<ExposeStateModel>,
    { form }: SetCurrentForm,
  ): any {
    patchState({
      currentForm: form,
    });
  }

  @Action(UpdateSnapshotList)
  updateSnapshotList(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { newItem, imageNumber, type }: UpdateSnapshotList,
  ): any {
    this.addToSnapshots(getState, patchState, type, imageNumber);

    let imagesDescriptionsList = this.getDescriptionsList(getState);

    const savedData = this.createDataForSnapshot(
      imagesDescriptionsList,
      imageNumber,
      type,
      newItem,
    );
    const snapshot = this.updateSnapshot(
      getState().aiSnapshotList,
      savedData,
      imageNumber,
      true,
    );
    patchState({
      aiSnapshotList: snapshot,
    });
  }

  @Action(RegenerateText)
  regenerateText(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { imageNumber, image, language, type }: RegenerateText,
  ): any {
    this.addToSnapshots(getState, patchState, type, imageNumber);
    let imagesDescriptionsList = this.getDescriptionsList(getState);

    return this.exposeService
      .regenerateTextForImage(image, language, imageNumber)
      .pipe(
        tap((res) => {
          if (res.status) {
            const savedData = this.createDataForSnapshot(
              imagesDescriptionsList,
              imageNumber,
              type,
              res,
            );
            const snapshot = this.updateSnapshot(
              getState().aiSnapshotList,
              savedData,
              imageNumber,
              true,
            );
            if (type === 'title') {
              imagesDescriptionsList[imageNumber].title = res.title;
            } else if (type === 'all') {
              imagesDescriptionsList[imageNumber].title = res.title;
              imagesDescriptionsList[imageNumber].answer = res.answer;
            } else {
              imagesDescriptionsList[imageNumber].answer = res.answer;
            }
            patchState({
              imagesDescriptions: imagesDescriptionsList,
              aiSnapshotList: snapshot,
            });
          }
        }),
        catchError((err) => {
          return throwError(err);
        }),
      );
  }

  @Action(GetDocumentChangesById)
  getDocumentChangesById(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { id }: GetDocumentChangesById,
  ): any {
    const snapshotList = getState().aiSnapshotList;
    const res = this.changeSnapshotIndexes(snapshotList, id);

    patchState({
      aiSnapshotList: res,
    });
  }

  @Action(SaveAITextsChanges)
  saveAITextsChanges(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { descriptions }: SaveAITextsChanges,
  ): any {
    patchState({
      imagesDescriptions: descriptions,
    });
  }
  @Action(ClearExposeState)
  clearExposeState(
    { patchState, getState }: StateContext<ExposeStateModel>,
    {}: ClearExposeState,
  ): any {
    patchState({
      imagesDescriptions: undefined,
      aiSnapshotList: undefined,
      geolocation: undefined,
      documentCreatingError: undefined,
      currentForm: undefined,
      documentTemplate: DocumentAttributesVoid as any,
      document: undefined,
      uploadedImages: undefined,
    });
  }

  @Action(ClearUploadedImages)
  clearUploadedImages(
    { patchState, getState }: StateContext<ExposeStateModel>,
    {}: ClearUploadedImages,
  ): any {
    patchState({
      uploadedImages: undefined,
    });
  }

  @Action(GetGeolocationByName)
  getGeolocationByName(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { placeName }: GetGeolocationByName,
  ): any {
    patchState({
      documentCreatingError: '',
    });
    return this.exposeService.getGeolocationByName(placeName).pipe(
      tap((res) => {
        if (res) {
          patchState({
            geolocationAll: res.filter((val, index) => {
              return val && index < 10;
            }),
            geolocation: res[0],
          });
        }
      }),
      catchError((err) => {
        patchState({
          geolocation: DEFAULT_GEOLOCATION,
        });
        return throwError(err);
      }),
    );
  }

  /// methods
  setFirstData(
    aiSnapshotList: AISnapshotList,
    imagesDescriptionsList: any[],
    imageNumber: number,
    type: string,
  ) {
    const list = aiSnapshotList ? aiSnapshotList : new AISnapshots();
    const data = {
      data:
        type === 'title'
          ? imagesDescriptionsList[imageNumber].title
          : imagesDescriptionsList[imageNumber].answer,
      type,
      id: imageNumber,
    };
    return { list, data };
  }

  createDataForSnapshot(
    imagesDescriptionsList: any[],
    imageNumber: number,
    type: string,
    res: any,
  ) {
    if (type === 'title') {
      imagesDescriptionsList[imageNumber].title = res.title;
    } else if (type === 'all') {
      imagesDescriptionsList[imageNumber].title = res.title;
      imagesDescriptionsList[imageNumber].answer = res.answer;
    } else {
      imagesDescriptionsList[imageNumber].answer = res.answer;
    }
    const savedData = {
      data: type === 'title' ? res.title : res.answer,
      type,
      id: imageNumber,
    };

    return savedData;
  }

  @Action(EditDocumentProduct)
  editDocumentProduct(
    { patchState, dispatch }: StateContext<ExposeStateModel>,
    { params, id }: EditDocumentProduct,
  ): Observable<any> {
    patchState({
      document: undefined,
    });
    return this.exposeService.editDocumentProduct(params, id).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            document: { ...params, product_id: id },
            documentTemplate: params.attributes,
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(SetDocumentProduct)
  setDocumentProduct(
    { patchState, dispatch }: StateContext<ExposeStateModel>,
    { product }: SetDocumentProduct,
  ): any {
    // console.log(product.attributes);
    patchState({
      document: product,
      documentTemplate: product.attributes,
    });
  }
  createAISnapshotLists(list: aiTextValue[]) {
    let snapshots = new AISnapshots();

    list.forEach((item, id) => {
      snapshots = this.updateSnapshot(snapshots, item, id, !!id);
    });
    return snapshots;
  }

  updateSnapshot(
    snapShotList: AISnapshotList,
    aiDescription: aiTextValue,
    id: number,
    isSaved = false,
  ) {
    const snapShotListClone = !snapShotList
      ? new AISnapshots()
      : JSON.parse(JSON.stringify(snapShotList));

    const value = aiDescription;
    const key = Md5.hashStr(JSON.stringify(aiDescription));

    if (
      !snapShotListClone.list ||
      !Object.keys(snapShotListClone.list).length
    ) {
      snapShotListClone.HEAD = key;
    }
    snapShotListClone.CURRENT = key;

    if (
      snapShotListClone.list &&
      Object.keys(snapShotListClone.list).length >= 40
    ) {
      snapShotListClone.HEAD = {
        ...snapShotListClone.list[snapShotListClone.HEAD],
      }.next;
      delete snapShotListClone.list[snapShotListClone.HEAD];
    }
    snapShotListClone.list[key] = new AISnapshot(value);

    if (isSaved) {
      snapShotListClone.SAVED = key;
    }
    snapShotListClone.TAIL = key;

    return snapShotListClone;
  }

  changeSnapshotIndexes(snapshotList: AISnapshotList, key: string) {
    const snapShotListClone = JSON.parse(JSON.stringify(snapshotList));
    snapShotListClone.CURRENT = key;

    return snapShotListClone;
  }

  getDescriptionsList(getState: any) {
    const imagesDescriptions = getState().imagesDescriptions
      ? getState().imagesDescriptions
      : getState().documentTemplate.expose_images.map((info: any) => ({
          answer: info.answer,
          title: info.title,
        }));
    // duplicate all items? - debug

    let imagesDescriptionsList = JSON.parse(JSON.stringify(imagesDescriptions));
    let imagesDescriptionsFirst = JSON.parse(
      JSON.stringify(imagesDescriptions[0]),
    );
    if (imagesDescriptionsList[0].title !== imagesDescriptionsList[1].title) {
      imagesDescriptionsList.unshift(imagesDescriptionsFirst);
    }

    return imagesDescriptionsList;
  }

  addToSnapshots(
    getState: any,
    patchState: any,
    type: string,
    imageNumber: number,
  ) {
    let imagesDescriptionsList = this.getDescriptionsList(getState);

    const aiSnapshotList = getState().aiSnapshotList
      ? JSON.parse(JSON.stringify(getState().aiSnapshotList))
      : null;
    let isSameItem = false;
    if (
      aiSnapshotList &&
      aiSnapshotList?.list &&
      Object.keys(aiSnapshotList.list).length
    ) {
      const keys = Object.keys(aiSnapshotList.list);
      const lastKey = keys[keys.length - 1];
      const lastItem = aiSnapshotList?.list[lastKey].value;
      isSameItem =
        lastItem && lastItem.type === type && lastItem.id === imageNumber;
    }
    if (!isSameItem) {
      const { list, data } = this.setFirstData(
        aiSnapshotList,
        imagesDescriptionsList,
        imageNumber,
        type,
      );
      patchState({
        aiSnapshotList: this.updateSnapshot(list, data, imageNumber, true),
      });
    }
  }

  @Action(GenerateVSImage)
  generateVSImage(
    { patchState, getState, dispatch }: StateContext<ExposeStateModel>,
    { img, style, roomType }: GenerateVSImage,
  ): any {
    patchState({
      vsGeneratedImages: [],
      vsSelectedImage: '',
    });
    return this.exposeService.generateVsImage(img, style, roomType).pipe(
      tap((res: GenerateVSImageRes) => {
        if (res.status) {
          patchState({
            vsGeneratedImages: [res.result.result_image_url],
            vsSelectedImage: res.result.result_image_url,
            vsRenderId: res.result.render_id,
          });
          this.notifications.success('Paid 1 credit', {
            text: 'View history',
            url: '/payments',
          });
        }
      }),
      catchError((err) => {
        patchState({
          vsGeneratedImages: [],
        });
        this.notifications.error('Error generating virtual staging', {
          text: 'View history',
          url: '/payments',
        });
        return throwError(err);
      }),
    );
  }

  @Action(ReGenerateVSImage)
  reGenerateVSImage(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { renderId, style, roomType, arrayLength }: ReGenerateVSImage,
  ): any {
    patchState({ error: false });

    let images = cloneDeep(getState().vsGeneratedImages);
    const length = 20 - images.length < 3 ? 20 - images.length : arrayLength;
    const array = Array.from('1'.repeat(length));
    let styles = getState().vsImagesStyles;
    let roomTypes = getState().vsImagesRoomTypes;
    const currentStyles = array.map((item) => this.translate.instant(style));
    const currentTypes = array.map((item) => this.translate.instant(roomType));
    return this.exposeService
      .generateSeveralVariations(array, renderId, style, roomType)
      .pipe(
        tap((res: VSImage) => {
          if (res) {
            images.push(res.result_image_url);
            patchState({
              vsGeneratedImages: images,
              vsSelectedImage: res.result_image_url,
              vsImagesStyles: [...styles, ...currentStyles],
              vsImagesRoomTypes: [...roomTypes, ...currentTypes],
              vsRenderId: res.render_id,
            });
          }
        }),
        catchError((err) => {
          patchState({ error: true });

          return throwError(err);
        }),
      );
  }

  @Action(GetDocument)
  getDocument(
    { getState, setState, patchState }: StateContext<ExposeStateModel>,
    { id }: GetDocument,
  ) {
    return this.exposeService.getProduct(id).pipe(
      tap((result) => {
        if (result.status) {
          patchState({
            document: result,
          });
        }
      }),
      catchError((err) => {
        patchState({
          document: undefined,
        });
        return throwError(err);
      }),
    );
  }

  @Action(GetGeneratedVSImages)
  getGeneratedVSImages(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { renderId }: GetGeneratedVSImages,
  ): any {
    let images = getState().vsGeneratedImages;
    let styles = getState().vsImagesStyles;
    let roomTypes = getState().vsImagesRoomTypes;
    return this.exposeService.getGeneratedVSImages(renderId).pipe(
      tap((res: VSResults) => {
        const stylesResults = res.result.outputs_styles.map((style) =>
          this.translate.instant(style),
        );
        const typesResults = res.result.outputs_room_types.map((type) =>
          this.translate.instant(type),
        );
        if (res.status) {
          patchState({
            vsGeneratedImages: [...images, ...res.result.outputs],
            vsImagesStyles: [...styles, ...stylesResults],
            vsImagesRoomTypes: [...roomTypes, ...typesResults],
            vsSelectedImage: res.result.outputs[0],
          });
        }
      }),
      catchError((err) => {
        patchState({
          vsGeneratedImages: [],
        });
        return throwError(err);
      }),
    );
  }

  @Action(ClearVSImages)
  clearVSImages(
    { patchState }: StateContext<ExposeStateModel>,
    {}: ClearVSImages,
  ): any {
    patchState({
      vsGeneratedImages: [],
      vsSelectedImage: '',
      vsRenderId: undefined,
    });
  }
  @Action(ViewVsOptions)
  viewVsOptions(
    { patchState, getState }: StateContext<ExposeStateModel>,
    {}: ViewVsOptions,
  ): any {
    return this.exposeService.getVSOptions().pipe(
      tap((res: any) => {
        if (res.status) {
          patchState({
            vsOptions: res.result,
          });
        }
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(UploadImages)
  uploadImages(
    { patchState, getState }: StateContext<ExposeStateModel>,
    { images }: UploadImages,
  ): any {
    return this.exposeService.uploadImages(images).pipe(
      tap((result: string[]) => {
        patchState({
          uploadedImages: result,
        });
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  @Action(SelectVSImage)
  selectVSImage(
    { patchState, dispatch }: StateContext<ExposeStateModel>,
    { img }: SelectVSImage,
  ): any {
    patchState({
      vsSelectedImage: img,
    });
  }
  @Action(SaveVSImage)
  saveVSImage(
    { patchState, dispatch, getState }: StateContext<ExposeStateModel>,
    { img, id }: SaveVSImage,
  ): any {
    const template = JSON.parse(JSON.stringify(getState().documentTemplate));
    template.expose_images[id] = img;
    patchState({
      documentTemplate: template,
    });
  }

  @Action(ClearPageForms)
  clearPageForms(
    { patchState }: StateContext<ExposeStateModel>,
    {}: ClearPageForms,
  ): any {
    patchState({
      documentTemplate: DocumentAttributesVoid as any,
    });
  }
}
