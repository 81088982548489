<div class="mobile-sidebar">
  <div class="mobile-sidebar__container">
    <div class="mobile-sidebar__header">
      <button
        mat-icon-button
        (click)="$event.stopPropagation()"
        class="mobile-sidebar__menu"
        [matMenuTriggerFor]="menu"
        aria-label="Page menu"
      >
        <mat-icon svgIcon="menu-icon"></mat-icon>
      </button>
      <div class="mobile-sidebar__title"></div>
    </div>
    <div class="mobile-sidebar__info">
      <div class="mobile-sidebar__notifications">
        <img src="/assets/images/icons/notification.svg" alt="" />
        <div class="mobile-sidebar__count">
          <span>1</span>
        </div>
      </div>
      <div class="mobile-sidebar__language">SH</div>
    </div>
  </div>
  <mat-menu #menu="matMenu" yPosition="below">
    <button
      mat-menu-item
      *ngFor="let menuItem of menuItems; let i = index"
      (click)="onSelectTab(i)"
      [disableRipple]="true"
    >
      <mat-icon [svgIcon]="menuItem.icon"></mat-icon>
      <span class="title-expose title-expose--size-14">
        {{ menuItem.title | translate }}
      </span>
    </button>
  </mat-menu>
</div>

<div class="wrapper-settings">
  <main class="container-settings">
    <section class="section-settings">
      <article class="section-expose__card-settings card-settings">
        <div class="card-settings__header">
          <div class="card-settings__header__icon">
            <mat-icon svgIcon="back-icon-expose"></mat-icon>
          </div>
        </div>
        <div class="card-settings__content">
          <div
            class="settings-page__menu"
            (click)="onSelectTab(i)"
            *ngFor="let menuItem of menuItems; let i = index"
          >
            <div
              class="settings-page__icon"
              [ngClass]="{ active: menuItem.isActive }"
            >
              <mat-icon [svgIcon]="menuItem.icon"></mat-icon>
            </div>
            <div
              class="settings-page__title title-expose title-expose--size-14"
              [ngClass]="{ active: menuItem.isActive }"
            >
              {{ menuItem.title | translate }}
            </div>
          </div>
        </div>
        <div
          class="card-settings__content card-settings__content--bottom-content"
        >
          <div
            class="settings-page__menu"
            (click)="onSelectSettings(i)"
            *ngFor="let item of bottomMenuItems; let i = index"
          >
            <div class="settings-page__icon">
              <mat-icon [svgIcon]="item.icon"></mat-icon>
            </div>
            <div class="settings-page__title title-expose text-expose">
              {{ item.title | translate }}
            </div>
          </div>
        </div>
      </article>
    </section>
  </main>
</div>
