import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import mapboxgl from 'mapbox-gl';
import {MapOptions} from "../store";
import {Config} from "./requests.config";
import {ZOOM} from "../../shared/components/map/map-config";

@Injectable({ providedIn: 'root' })
export class MapService {
  mapInstance!: mapboxgl.Map;
  markerInstance!: mapboxgl.Marker;

  constructor(private zone: NgZone) {}

  initMap(options: MapOptions): void {
    mapboxgl.accessToken = Config.mapKey;
    const map = new mapboxgl.Map(options);
    // TODO: try to fix without timeout
    setTimeout(() => {
      map.resize();
    }, 300);
    map.resize();
    this.updateMaxZoom(map, ZOOM.MaxZoom);
    this.updateMinZoom(map, ZOOM.MinZoom);
    map.scrollZoom.setWheelZoomRate( 1 );
      this.mapInstance = map;
      // this.mapCreated$.next(this.mapInstance);
  }

  destroyMap(): void {
    if (this.mapInstance) {
      this.mapInstance.remove();
    }
    // TODO: read mapbox gl draw documentation to fix `TypeError: Cannot read property 'delete' of null`
    /*if (this.drawInstance) {
      this.drawInstance.deleteAll();
      this.mapInstance.removeControl(this.drawInstance);
    }*/
  }

  updateMinZoom(map: mapboxgl.Map, minZoom: number): void {
    return this.zone.runOutsideAngular(() => {
      map.setMinZoom(minZoom);
    });
  }

  updateMaxZoom(map: mapboxgl.Map, maxZoom: number): void {
    return this.zone.runOutsideAngular(() => {
      map.setMaxZoom(maxZoom);
    });
  }

  zoomOut(mapContainer: string): void {
    return this.zone.runOutsideAngular(() => {
        this.mapInstance.zoomOut();
    });
  }

  zoomIn(mapContainer: string): void {
    return this.zone.runOutsideAngular(() => {
        this.mapInstance.zoomIn();
    });
  }
}
