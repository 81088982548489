import { Injectable } from '@angular/core';
import {
  concatMap,
  delayWhen,
  filter,
  flatMap,
  from,
  interval,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  toArray,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import {
  AddressSearch,
  AIDescriptions,
  DocumentAttributes,
  GenerateVSImageRes,
  GetGeneratedVSImages,
  RegenerateText,
  SecondPageAttributes,
  ThirdPageAttributes,
  VSResults,
} from '../store';
import * as buffer from 'buffer';
import { Config } from '../../../core/services/requests.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ExposeService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  upload(type: string, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(
      `${environment.apiUrl}/attachment/${type}/${environment.botId}`,
      formData,
    );
  }

  getAITextsForImages(
    expose_images: { image: string; category: string }[],
    attributes: DocumentAttributes,
    language: string,
  ): Observable<any[]> {
    console.log(expose_images);
    return from(expose_images)
      .pipe(
        concatMap((info: { image: string; category: string }) => {
          return this.http.post<any[]>(
            `${environment.techfunderUrl}/advertising`,
            {
              project: 'realty_ai',
              answer_language: language,
              temperature: 0,
              num_words_answer: 65,
              num_words_title: 4,
              details: [
                {
                  type: 'image',
                  url: info.image,
                  conditional_image_captioning: this.getImageInfo(
                    info.category,
                    attributes,
                  ),
                },
              ],
            },
          );
        }),
      )
      .pipe(toArray());
  }

  getImageInfo(category: string, attributes: DocumentAttributes): string {
    const nullCategories = ['Nicht zugeordnet'];
    let additionalInfo = '';
    if (category && !nullCategories.includes(category)) {
      const roomData = Config.RoomsParams.find(
        (item) => item.roomType === category,
      );
      additionalInfo = this.translateService.instant(category);
      const objType = this.translateService.instant(
        attributes.first_page_forms.object_type,
      );
      if (roomData) {
        const obj = attributes[roomData.attribute as keyof DocumentAttributes];
        const space = ['balcony_space', 'terrace_area'];
        const price = ['parking_costs'];

        if (obj) {
          const info = roomData.subAttributes
            .map((item) => {
              let unit = space.includes(item) ? 'm²' : '';
              unit = price.includes(item) ? '€' : unit;
              const translated =
                obj[item] && !Number(obj[item])
                  ? this.translateService.instant(obj[item] as string)
                  : obj[item];
              return obj[item]
                ? `${item.split('_').join(' ')}: ${translated}` + unit
                : '';
            })
            .filter((item) => item)
            .join(',');
          additionalInfo = `${additionalInfo}, ${info}`;
        }
      }
      return `${additionalInfo}, in a ${objType}`;
    } else {
      return '';
    }
  }

  uploadImages(images: any[]): Observable<string[]> {
    return from(images)
      .pipe(
        concatMap((file: any) => {
          const formData = new FormData();
          formData.append('file', file);

          return this.http.post(
            `${environment.apiUrl}/attachment/image/${environment.botId}`,
            formData,
          );
        }),
      )
      .pipe(filter((res: any) => res.status))
      .pipe(map((res: any) => res.url))
      .pipe(toArray());
  }

  setDataForImages(descriptions: string[], id: string): Observable<any> {
    const info = descriptions.map((item) => ({ type: 'text', url: item }));
    return this.http.post<any[]>(`${environment.techfunderUrl}/data`, {
      object_id: id,
      details: [
        {
          type: 'text',
          url: info,
        },
      ],
    });
  }

  public addProduct(product: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/product`, product, {});
  }

  regenerateTextForImage(
    image: string,
    language: string,
    index: number,
  ): Observable<any> {
    return this.http.post<AIDescriptions>(
      `${environment.techfunderUrl}/advertising`,
      {
        project: 'realty_ai',
        answer_language: language,
        details: [
          {
            type: 'image',
            url: image,
          },
        ],
      },
    );
  }

  getGeolocationByName(placeName: string): Observable<AddressSearch[]> {
    return this.http.get<AddressSearch[]>(
      `https://nominatim.openstreetmap.org/search.php?q=${placeName}&polygon_geojson=1&format=jsonv2`,
      {
        headers: new HttpHeaders({
          'accept-language': 'en',
        }),
      },
    );
  }

  public editDocumentProduct(product: any, id: string): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/api/product/${id}`,
      product,
      {},
    );
  }

  public generateVsImage(
    img: string,
    style: string,
    roomType: string,
  ): Observable<GenerateVSImageRes> {
    return this.http.post<GenerateVSImageRes>(
      `${environment.apiUrl}/service/${environment.botId}/virtual-staging/create`,
      {
        image_url: img,
        style: style,
        room_type: roomType,
      },
      // comment headers
      //   {
      //     headers: {
      //       'X-Access-Token':
      //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOiJhY2NvdW50IiwiYWNjb3VudF9pZCI6IkVZMEU3VFlCQlpKSFM3TCIsImlhdCI6MTY5MzM5NTU4OX0.qALyKfKG1DLZLCIxHKNsCc7eapT1APYsma0csQaf-wQ',
      //     },
      //   },
    );
    // return of({
    //   status: true,
    //   result: {
    //     result_image_url:
    //       'https://storage.googleapis.com/furniture-ai.appspot.com/users/aUQLpgU3MoNZHX9mLacLwmatI9n1/renders/zyRx2GI8ozMjD5gNOzdp/outputs/tyrkpsrbxdipfncrfsjqmiwmqq_0.png?GoogleAccessId=furniture-ai%40appspot.gserviceaccount.com&Expires=10345432335&Signature=etiezDRZwjmGucwcsF1LNnajwlnVabi86HGOV0OHzS7TGVv9vmvm3ASzR4NpcqTdkTV7sWEUCfLbEajR15ZwlyJP5agM2PpWfCuaF6Yy2rp83B%2Fpp0toq60GJpfm4iWDdzy9pG1eGp3sc95UDXpcnbIf9tIcpl3jjd7s46ARDwivTweHb%2FQ0xiSaIjptxJnyyFY%2BncP4aUoTqetlarevtSw%2BgJ%2Fos%2FgvZFYDw7%2FWU4Q58FSFQ2t7INbuxgh2MHfRv3CT60nZHPS7EzDdry7mW0eyqLlHgphErFQrb%2Bdj2dcedg9NI3UPRQx%2FCNyzg0E%2BpemhJ%2B8WBkOfVZgJFhzrtQ%3D%3D',
    //     render_id: 'zyRx2GI8ozMjD5gNOzdp',
    //   },
    // });
  }

  public reGenerateVsImage(
    renderId: string,
    style: string,
    roomType: string,
  ): Observable<GenerateVSImageRes> {
    return this.http.post<GenerateVSImageRes>(
      `${environment.apiUrl}/service/${environment.botId}/virtual-staging/${renderId}/variation`,
      {
        style: style,
        room_type: roomType,
      },
      // comment headers
      //   {
      //     headers: {
      //       'X-Access-Token':
      //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOiJhY2NvdW50IiwiYWNjb3VudF9pZCI6IkVZMEU3VFlCQlpKSFM3TCIsImlhdCI6MTY5MzM5NTU4OX0.qALyKfKG1DLZLCIxHKNsCc7eapT1APYsma0csQaf-wQ',
      //     },
      //   },
    );
    // return of({
    //   status: true,
    //   result: {
    //     result_image_url:
    //       'https://storage.googleapis.com/furniture-ai.appspot.com/users/aUQLpgU3MoNZHX9mLacLwmatI9n1/renders/zyRx2GI8ozMjD5gNOzdp/outputs/tyrkpsrbxdipfncrfsjqmiwmqq_0.png?GoogleAccessId=furniture-ai%40appspot.gserviceaccount.com&Expires=10345432335&Signature=etiezDRZwjmGucwcsF1LNnajwlnVabi86HGOV0OHzS7TGVv9vmvm3ASzR4NpcqTdkTV7sWEUCfLbEajR15ZwlyJP5agM2PpWfCuaF6Yy2rp83B%2Fpp0toq60GJpfm4iWDdzy9pG1eGp3sc95UDXpcnbIf9tIcpl3jjd7s46ARDwivTweHb%2FQ0xiSaIjptxJnyyFY%2BncP4aUoTqetlarevtSw%2BgJ%2Fos%2FgvZFYDw7%2FWU4Q58FSFQ2t7INbuxgh2MHfRv3CT60nZHPS7EzDdry7mW0eyqLlHgphErFQrb%2Bdj2dcedg9NI3UPRQx%2FCNyzg0E%2BpemhJ%2B8WBkOfVZgJFhzrtQ%3D%3D',
    //     render_id: 'zyRx2GI8ozMjD5gNOzdp',
    //   },
    // });
  }

  // {id: "due6xPdJu7DukT4GDYCM", url: "https://api.brn.ai/upload/img/messages/IbgGj1cJyA.jpg"}
  public getGeneratedVSImages(renderId: string): Observable<VSResults> {
    return this.http.get<VSResults>(
      `${environment.apiUrl}/service/${environment.botId}/virtual-staging/render/${renderId}`,

      {
        headers: {
          'X-Access-Token':
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOiJhY2NvdW50IiwiYWNjb3VudF9pZCI6IkVZMEU3VFlCQlpKSFM3TCIsImlhdCI6MTY5MzM5NTU4OX0.qALyKfKG1DLZLCIxHKNsCc7eapT1APYsma0csQaf-wQ',
        },
      },
    );
  }

  getProduct(id: string): Observable<any> {
    return this.http.get<any[]>(
      `${environment.apiUrl}/api/product/${id}`, //dev bot
    );
  }
  public getVSOptions(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/service/${environment.botId}/virtual-staging/options`,

      // {
      //   headers: {
      //     'X-Access-Token':
      //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOiJhY2NvdW50IiwiYWNjb3VudF9pZCI6IkVZMEU3VFlCQlpKSFM3TCIsImlhdCI6MTY5MzM5NTU4OX0.qALyKfKG1DLZLCIxHKNsCc7eapT1APYsma0csQaf-wQ',
      //   },
      // },
    );
  }

  generateSeveralVariations(
    ids: string[],
    renderId: string,
    style: string,
    roomType: string,
  ): Observable<any> {
    return from(ids)
      .pipe(
        delayWhen((id, index) => (index > 0 ? interval(0) : interval(40000))),
      )
      .pipe(
        concatMap((id: string) => {
          return this.http.post<any>(
            `${environment.apiUrl}/service/${environment.botId}/virtual-staging/${renderId}/variation`,
            {
              style,
              room_type: roomType,
            },
          );
        }),
      )
      .pipe(map((res) => res.result));
    // .pipe(toArray());
  }

  compressImage = async (blobImg: any, percent: number) => {
    let bitmap = await createImageBitmap(blobImg);
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    ctx?.drawImage(bitmap, 0, 0);

    return await new Promise((resolve) =>
      canvas.toBlob(resolve, 'image/jpeg', percent / 100),
    );
  };
}
