export class UploadImage {
  static readonly type = '[Mvp] Upload image';
  constructor(public files: FormData[]) {}
}

export class GetLocation {
  static readonly type = '[Mvp] Get location';
  constructor(
    public lat: number,
    public lon: number,
  ) {}
}

export class AddProduct {
  static readonly type = '[Mvp] Add Product';
  constructor(public params: any) {}
}

export class AddDataForAdvertisement {
  static readonly type = '[Mvp] Add Data For Advertisement';
  constructor(public params: any, public user_id: string) {}
}

export class CreateAdvertisement {
  static readonly type = '[Mvp] Create Advertisement';
  constructor(
    public user_id: string,
    public object_id: string,
  ) {}
}

export class EditNftProduct {
  static readonly type = '[Mvp] Edit NFT Product';
  constructor(
    public params: any,
    public id: string,
  ) {}
}

export class SaveProductTemporaryInfo {
  static readonly type = '[Mvp] Save Product Temporary Info';
  constructor(public params: any) {}
}

// export class GetBotCategories {
//   static readonly type = '[Mvp] Get Categories';
//
//   constructor() {}
// }

export class GetProduct {
  static readonly type = '[Mvp] Get product';
  constructor(
    public id: string,
    public botId?: string,
  ) {}
}

export class GetPathname {
  static readonly type = '[Mvp] Get pathname';
  constructor(public pathname: string) {}
}

export class ClearState {
  static readonly type = '[Mvp] Clear State';
  constructor() {}
}

export class SetProductId {
  static readonly type = '[Mvp] Set Product Id';
  constructor(public productId: string) {}
}

